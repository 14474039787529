export class PersonInfoConstant {
  static readonly NAME_SALUTATION: string = "nameSalutation";
  static readonly FIRST_NAME: string = "firstName";
  static readonly MIDDLE_NAME: string = "middleName";
  static readonly LAST_NAME: string = "lastName";
  static readonly NAME_SUFFIX: string = "nameSuffix";
  static readonly TAX_ID: string = "taxId";
  static readonly DATE_OF_BIRTH: string = "dateOfBirth";
  static readonly GENDER: string = "gender";
  static readonly PNG: string = "PNG";
  static readonly CUSTOMER: string = "CUSTOMER";
  static readonly SIGNATURE: string = "signature";
  static readonly PERSON_TYPE: string = "personType";
}
