const SLIDER_PANES = Object.freeze({
    selectAccount: {
      title: "Select an account to open",
      id: "select-account"
    },
    confirmAccount: {
      title: "Confirm account selection",
      id: "confirm-account"
    }
  });

  export default SLIDER_PANES;