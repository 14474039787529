<script lang="ts">
import "@banno/jha-wc/src/forms/jha-form-text-input/jha-form-text-input.js";
import "@banno/jha-wc/src/jha-flex-wrapper/jha-flex-wrapper.js";
import "@banno/jha-wc/src/forms/jha-form-date-input-icon/jha-form-date-input-icon.js";
import "@banno/jha-wc/src/forms/jha-form-ssn/jha-form-ssn.js";
import "@banno/jha-wc/src/forms/jha-form-select/jha-form-select.js";
import "@banno/jha-wc/src/forms/jha-form-signature-input/jha-form-signature-input.js";
import { $inj, $injByInterface } from "@/common/decorators/depinject";
import {
  LanguageFactory,
  ApplicantFactory,
  ApplicantPersonFactory,
  ApplicantImageFactory,
  BltSignatureFactory,
  CustomerSearchFactory,
  TaxIdValidationService
} from "@/common/services/services.module";
import UI_FIELDS_CATEGORIES from "@/common/enums/UIFieldsEnum";
import "@banno/jha-wc/src/icons/jha-icon-information.js";
import type IEmpWorkspaceStore from "@/branchmanager/stores/IEmpWorkspaceStore";
import { WorkflowStatesConstant } from "@/common/constant/WorkflowStatesConstant";
import { bltSignatureField } from "@/common/components/appch.components.module";
import { defineComponent, ref } from "vue";
import type IBltInput from "@/common/directives/bltInputField/IBltInput";
import { PersonInfoConstant } from "@/common/constant/PersonInfoConstant";
import REGEX from "@/common/enums/regexEnum";
import { LOOKUPCONSTANT } from "@/common/constant/LookupConstant";
import type IWorkspaceMetadataStore from "@/common/services/Workspace/IWorkspaceMetadataStore";
import type IUiFieldsFactory from "@/common/services/Fields/IUiFieldsFactory";
import type IApplicantValidationFactory from "@/common/services/Applicant/IApplicantValidationFactory";
import { BranchLanguageKeysConstant } from "@/branchmanager/app/constant/BranchLanguageKeysConstant";
import type UIField from "@/common/data/UIFields";
import type { Validation } from "@/common/models/IStateValidationResponse";
import { workspaceStatusConstant } from "@/branchmanager/app/constant/workspaceStatusConstant";
import type IWorkspace from "@/common/models/IWorkspace";
import type IPerson from "@/common/models/IPerson";
import type IApplicant from "@/common/models/IApplicant";
import type INotificationFactory from "@/common/services/INotificationFactory";
import WORKFLOW_TYPES from "@/common/enums/workflowTypesEnum";
import { NotificationMessageCategory } from "@/branchmanager/app/constant/NotificationMessageCategory";
import { NotificationConstants } from "@/common/constant/NotificationConstants";
import EmployeeWorkflowFactory from "@/branchmanager/services/EmployeeWorkflowFactory";
import {PersonInfoStore} from "@/branchmanager/stores/PersonInfoStore";
import BltEmpForm from "@/branchmanager/app/components/bltForm/bltEmpForm.vue";
import BltForm from "@/common/components/bltForm/bltForm.vue";
import FormValidationService from "@/common/components/bltForm/formValidationService";

export default defineComponent({
  name: "PersonInfoState",
  components: {
    BltForm,
    BltEmpForm,
    bltSignatureField
  },
  emits: ["beforeSave", "afterSave", "afterLoad", "updateWorkspaceEntityValidation"],
  setup() {
    const applicantValidationFactory = $injByInterface<IApplicantValidationFactory>("IApplicantValidationFactory");
    const uiFieldsFactory = $injByInterface<IUiFieldsFactory>("IUiFieldsFactory");
    const workflowFactory = $inj(EmployeeWorkflowFactory);

    const applicantFactory = $inj(ApplicantFactory);
    const languageFactory = $inj(LanguageFactory);
    const uniqueTaxIdValidation = $inj(TaxIdValidationService);
    const applicantPersonFactory = $inj(ApplicantPersonFactory);
    const workspaceStore = $injByInterface<IEmpWorkspaceStore>("IWorkspaceStore");
    const applicantImageFactory = $inj(ApplicantImageFactory);
    const customerSearchFactory = $inj(CustomerSearchFactory);
    const bltSignatureFactory = $inj(BltSignatureFactory);
    const workspaceMetadataStore = $injByInterface<IWorkspaceMetadataStore>("IWorkspaceMetadataStore");
    const notificationFactory = $injByInterface<INotificationFactory>("INotificationFactory");
    const personInfoStore = PersonInfoStore()
    const formValidationService = $inj(FormValidationService)

    return {
      applicantValidationFactory,
      uiFieldsFactory,
      workflowFactory,
      applicantFactory,
      applicantPersonFactory,
      languageFactory,
      workspaceStore,
      applicantImageFactory,
      bltSignatureFactory,
      notificationFactory,
      workspaceMetadataStore,
      customerSearchFactory,
      uniqueTaxIdValidation,
      personInfoStore,
      formValidationService
    };
  },

  data() {
    return {
      state: WorkflowStatesConstant.PERSON_INFO.STATE,
      OpenLanguageKeysConstant: BranchLanguageKeysConstant,
      PersonInfoConstant,
      LOOKUPCONSTANT,
      NotificationMessageCategory,
      continueHeaderWrapper: "continue-header-wrapper" as string,
      nameSalutation: ref(),
      firstName: ref(),
      middleName: ref(),
      lastName: ref(),
      nameSuffix: ref(),
      taxId: ref(),
      dateOfBirth: ref(),
      gender: ref(),
      showSignatureDialog: false,
      field: [] as UIField[],
      person: {} as IPerson,
      applicantData: {} as IApplicant,
      signatureImg: {} as {
        image?: string;
        imageId?: number;
      },
      genderText: "",
      salutationText: "",
      signatureDialogTitleText: "",
      errorMessageRequired: "",
      personTitle: "",
      loading: false,
      contentMarginClass: "personinfo-space",
      ssnWarningMessage: "",
      personTitleStatic: false
    };
  },
  async created() {
    this.loading = true;
    [this.errorMessageRequired, this.genderText, this.salutationText, this.signatureDialogTitleText, this.personTitle] =
      await Promise.all([
        this.languageFactory.get(this.OpenLanguageKeysConstant.ERROR_MESSAGE_REQUIRED),
        this.languageFactory.get(this.OpenLanguageKeysConstant.OPEN_PERSONINFO_LABEL_GENDER),
        this.languageFactory.get(this.OpenLanguageKeysConstant.OPEN_PERSONINFO_LABEL_SALUTATION),
        this.languageFactory.get(this.OpenLanguageKeysConstant.OPEN_PERSONINFO_SIGNATURE_HEADER),
        this.languageFactory.get(this.OpenLanguageKeysConstant.BRANCH_PERSON_TITLE)
      ]);
    await this.loadData();
  },
  computed: {
    workspaceUUID(): string {
      return this.workspaceStore.workspaceUUID;
    },
    applicantId() {
      return this.workspaceStore.applicant.applicantId;
    },
    trackerUUID(): string {
      return this.workspaceStore.trackerUUID;
    },
    fieldList(): UIField[] {
      return this.field;
    },
    signatureImage(): {
      image?: string;
      imageId?: number;
    } {
      return this.signatureImg;
    },
    genderLabel(): string {
      return this.genderText;
    },
    signatureDialogTitle(): string {
      return this.signatureDialogTitleText;
    },
    salutationLabel(): string {
      return this.salutationText;
    },
    isBlankOrInvalid(): string {
      return this.errorMessageRequired;
    },
    isSSNValid(): string {
      return this.ssnWarningMessage;
    },
    isLoading(): boolean {
      return this.loading;
    },
    currentWorkspace(): IWorkspace {
      return this.workspaceStore.workspace;
    },
    isworkspaceLocked(): boolean {
      return this.currentWorkspace?.statusCode === workspaceStatusConstant.CLOSED;
    },
    isWorkspaceClosed(): boolean {
      return (
        this.currentWorkspace?.statusCode === workspaceStatusConstant.CLOSED ||
        this.workspaceStore.applicant.locked ||
        this.applicantData.locked
      );
    },
    showSSNErrorMEssage(): boolean {
      return !!(this.isSSNValid && this.isSSNValid.length > 0);
    }
  },
  methods: {
    async coreTaxIdUniqueValidation(): Promise<boolean> {
      return await this.uniqueTaxIdValidation.coreTaxIdUnique(this.workspaceUUID, this.taxId, WORKFLOW_TYPES.PERSON);
    },
    async workspaceTaxIdUniqueValidation(): Promise<boolean> {
      if (this.workspaceStore.applicant) {
        return await this.uniqueTaxIdValidation.workspaceTaxIdUnique(
            this.workspaceUUID,
            this.taxId,
            this.workspaceStore.applicant.applicantId,
            this.workspaceStore.applicant.applicantType
        )
        } else { return Promise.reject() }
    },
    async duplicateSSNValidation(): Promise<void> {
      if (!this.taxId || !REGEX.TAX_ID.test(this.taxId)) {
        this.ssnWarningMessage = this.errorMessageRequired;
        return;
      }
      const validateExistingWorkspaceId = await this.workspaceTaxIdUniqueValidation().catch((e) => {
        console.log(e)
        this.ssnWarningMessage = e.message;
      });

      //If workspace validation fail don't check coreTaxId
      if (!validateExistingWorkspaceId) {
        return;
      }

      const validateExistingCoreId = await this.coreTaxIdUniqueValidation().catch((e) => {
        console.log(e)
        this.ssnWarningMessage = e.message;
      });

      //If coreTax validtaion passes and workspace passed or it should not reach here
      if (validateExistingCoreId) {
        this.ssnWarningMessage = "";
      }
    },
    async loadData(): Promise<void> {
      this.loading = true;

      [this.field, this.person, this.applicantData, this.signatureImg] = await Promise.all([
        this.uiFieldsFactory.get(UI_FIELDS_CATEGORIES.BRANCH.PERSON_INFO),
        this.applicantPersonFactory.get(this.workspaceUUID, this.workspaceStore.applicant.applicantId),
        this.applicantFactory.getApplicant(this.workspaceUUID, this.workspaceStore.applicant.applicantId),
        this.getSignatureImage()
      ]);

      this.signatureImage ??= {};
      this.field ??= [];

      if (!this.person) {
        this.loading = false;
        return;
      }

      this.nameSalutation = this.person?.nameSalutation ?? "";
      this.firstName = this.person?.firstName ?? "";
      this.middleName = this.person?.middleName ?? "";
      this.lastName = this.person?.lastName ?? "";
      this.nameSuffix = this.person?.nameSuffix ?? "";
      this.taxId = this.person?.taxId ?? "";
      this.dateOfBirth = this.person?.dateOfBirth ?? "";
      this.gender = this.person?.gender ?? "";

      this.loading = false;

      this.$emit("afterLoad", {
        person: this.person,
        fieldList: this.field,
        applicantPersonDisabledData: this.isWorkspaceClosed
      });
    },
    async getSignatureImage(): Promise<any> {
      const imageList = await this.applicantImageFactory.getAll(this.workspaceUUID, this.workspaceStore.applicant);
      return this.applicantImageFactory.getSignatureFromImageList(imageList);
    },
    lookupChange(key: "nameSalutation" | "gender", event: string) {
      if (event !== "undefined") {
        this[key] = event;
      } else {
        this[key] = undefined;
      }
    },
    isRequired(dataField: string): boolean {
      if (!this.fieldList) return false;
      const result = this.fieldList.filter((el: IBltInput) => {
        return el.enabled && el.required && el.field === dataField;
      });
      return result.length > 0;
    },
    isVisible(dataField: string): boolean {
      if (!this.fieldList) return false;
      const result = this.fieldList.filter((el: IBltInput) => {
        return el.enabled && el.field === dataField;
      });
      return result.length > 0;
    },
    signatureChanged(event: { detail: string }): void {
      this.languageFactory.get(this.OpenLanguageKeysConstant.OPEN_PERSONINFO_SIGNATURE_HEADER).then((verbiage) => {
        this.bltSignatureFactory.collectSignature(event, verbiage);
      });
      const strippedBase64 = event.detail.split("base64,")[1];
      if (this.signatureImg.imageId) {
        this.updateSignature(strippedBase64);
      } else {
        this.addSignature(strippedBase64);
      }
    },

    updateSignature(strippedBase64: string): void {
      if (this.signatureImg.imageId) {
        this.applicantImageFactory
          .putApplicantSignature(
            this.workspaceStore.workspaceUUID,
            this.workspaceStore.applicant.applicantId,
            {
              strippedBase64,
              extension: PersonInfoConstant.PNG
            },
            this.signatureImg.imageId
          )
          .then((response) => {
            this.signatureImg.image = response.imageDataBase64;
            this.signatureImg.imageId = response.imageId;
            this.$emit("updateWorkspaceEntityValidation");
          });
      }
    },
    addSignature(strippedBase64: string): void {
      this.applicantImageFactory
        .postApplicantSignature(this.workspaceStore.workspaceUUID, this.workspaceStore.applicant.applicantId, {
          strippedBase64,
          extension: PersonInfoConstant.PNG
        })
        .then((response) => {
          this.signatureImg.image = response.imageDataBase64;
          this.signatureImg.imageId = response.imageId;
          this.$emit("updateWorkspaceEntityValidation");
        });
    },
    clearNotificationMessages(): void {
      this.workspaceMetadataStore.notification.messages = this.workspaceMetadataStore.notification.messages.filter(
        (message) =>
          message.messageCategory === NotificationMessageCategory.WORKFLOW &&
          message.type !== NotificationConstants.ERROR
      );
    },
    async beforeContinue(): Promise<void> {

      this.person = this.person || {};
      this.person.personType = [this.personInfoStore.applicantType];
      this.person.nameSalutation = this.nameSalutation;
      this.person.firstName = this.firstName;
      this.person.middleName = this.middleName;
      this.person.lastName = this.lastName;
      this.person.nameSuffix = this.nameSuffix;
      this.person.taxId = this.taxId;
      this.person.dateOfBirth = this.dateOfBirth;
      this.person.gender = this.gender;

      this.$emit("beforeSave", this.person);

      return this.applicantPersonFactory
        .save(
          this.workspaceStore.workspaceUUID,
          this.workspaceStore.applicant.applicantId,
          this.person || { personType: [PersonInfoConstant.CUSTOMER] }
        )
        .then(() => {
          this.$emit("afterSave", this.person);
        });


    },

    isEmptyOrSpaces(value: string): boolean {
      return value === null || value?.trim() === "" || value === undefined;
    }
  }
});
</script>
<template>
  <div>
    <blt-emp-form
      :before-continue="beforeContinue"
      :loading="isLoading"
      :backDisabled="false"
      :contentCardClass="continueHeaderWrapper"
      :disabled="false"
      showCurrentApplicant
      :bltUiText="personTitle"
      :staticTitle="!personTitleStatic"
      :contentMarginClass="contentMarginClass"
      currentStateCssClass="person-info-state-current-applicant"
      :workspace-uuid="workspaceUUID"
      :applicant-id="applicantId"
      validation="PERSON_INFO"
      validation-type="APPLICANT">
      <div class="person-info">
        <jha-flex-wrapper>
          <slot name="slotDynamicField1"></slot>
          <div v-blt-input-field="fieldList" :name="PersonInfoConstant.NAME_SALUTATION">
            <bltLookupSelector
              v-if="isVisible(PersonInfoConstant.NAME_SALUTATION)"
              name="salutation"
              :require="isRequired(PersonInfoConstant.NAME_SALUTATION)"
              :bltLookup="LOOKUPCONSTANT.SALUTATION"
              :value="nameSalutation"
              :allow-clear="true"
              :title="OpenLanguageKeysConstant.OPEN_PERSONINFO_LABEL_SALUTATION"
              :bltLookupOptions="{ enabled: true }"
              @lookupChange="lookupChange('nameSalutation', $event.detail)"
              :disabled="isWorkspaceClosed"
            >
            </bltLookupSelector>
          </div>
        </jha-flex-wrapper>
      </div>
      <div>
        <jha-flex-wrapper>
          <jha-form-text-input
            outline
            v-blt-ui-text="OpenLanguageKeysConstant.OPEN_PERSONINFO_FIRSTNAME"
            v-model="firstName"
            name="firstName"
            v-blt-input-field="fieldList"
            :warning="isBlankOrInvalid"
            :disabled="isWorkspaceClosed"
          ></jha-form-text-input>
          <jha-form-text-input
            outline
            v-blt-ui-text="OpenLanguageKeysConstant.OPEN_PERSONINFO_MIDDLENAME"
            v-model="middleName"
            name="middleName"
            v-blt-input-field="fieldList"
            :warning="isBlankOrInvalid"
            :disabled="isWorkspaceClosed"
          >
          </jha-form-text-input>
        </jha-flex-wrapper>
        <jha-flex-wrapper>
          <jha-form-text-input
            outline
            v-blt-ui-text="OpenLanguageKeysConstant.OPEN_PERSONINFO_LASTNAME"
            v-model="lastName"
            name="lastName"
            v-blt-input-field="fieldList"
            :warning="isBlankOrInvalid"
            :disabled="isWorkspaceClosed"
          ></jha-form-text-input>
          <jha-form-text-input
            outline
            v-blt-ui-text="OpenLanguageKeysConstant.OPEN_PERSONINFO_SUFFIX"
            v-model="nameSuffix"
            name="nameSuffix"
            v-blt-input-field="fieldList"
            :warning="isBlankOrInvalid"
            :disabled="isWorkspaceClosed"
          >
          </jha-form-text-input>
        </jha-flex-wrapper>
        <jha-form-row>
          <jha-form-ssn
            outline
            v-blt-ui-text="OpenLanguageKeysConstant.OPEN_PERSONINFO_TAXID"
            inputmode="numeric"
            aria-label="Input your Social Security Number"
            v-model="taxId"
            name="taxId"
            ui-mask-placeholder-char="space"
            v-blt-input-field="fieldList"
            :warning="isSSNValid"
            :showWarning="showSSNErrorMEssage"
            @input="duplicateSSNValidation"
            :disabled="isWorkspaceClosed"
          ></jha-form-ssn>

          <jha-form-date-input-icon
            outline
            v-blt-ui-text="OpenLanguageKeysConstant.OPEN_PERSONINFO_DOB"
            v-model="dateOfBirth"
            name="dateOfBirth"
            v-blt-input-field="fieldList"
            :warning="isBlankOrInvalid"
            :disabled="isWorkspaceClosed"
          >
          </jha-form-date-input-icon>
        </jha-form-row>
        <jha-flex-wrapper class="gender-pos" name="gender" v-blt-input-field="fieldList">
          <bltLookupSelector
            name="gender"
            v-if="isVisible('gender')"
            :require="isRequired('gender')"
            :bltLookup="LOOKUPCONSTANT.GENDER"
            :value="gender"
            :allow-clear="true"
            :title="OpenLanguageKeysConstant.OPEN_PERSONINFO_LABEL_GENDER"
            @lookupChange="lookupChange('gender', $event.detail)"
            :disabled="isWorkspaceClosed"
          >
          </bltLookupSelector>
        </jha-flex-wrapper>
        <jha-flex-wrapper name="signature" v-blt-input-field="fieldList">
          <bltSignatureField
            @signature-changed="signatureChanged"
            :signatureImage="signatureImage.image"
            :warning="isBlankOrInvalid"
            :dialogBorder="false"
            :dialogTitle="signatureDialogTitle"
            :signatureLabel="signatureDialogTitle"
            :disableClick="isworkspaceLocked"
          ></bltSignatureField>
        </jha-flex-wrapper>
      </div>
    </blt-emp-form>
  </div>
</template>

<style scoped>
:host {
  display: block;
}

jha-flex-wrapper {
  gap: 8px;
  align-items: flex-start;
}

jha-flex-wrapper > * {
  flex: 1;
  margin-bottom: 0px;
}

jha-form-floating-group {
  margin-bottom: 0px;
}
input[type="date"] {
  padding: 17px 12px !important;
}
jha-form-date-input-icon {
  --jha-input-margin-bottom: 0;
}
.jh-card-header {
  border: none !important;
}
:deep(.blt-signature-field-wrapper) {
  margin-top: var(--jh-space-400);
}
:deep(.blt-signature-field-wrapper > label) {
  font-weight: 400;
  color: var(--jha-text-dark);
  font-size: 14px;
  margin-bottom: var(--jh-space-200);
  display: block;
}
:deep(.signature) {
  margin: 8px 0 0px;
}
:deep(.edit)[disabled] {
  color: var(--primary-button-disabled-color) !important;
  fill: var(--primary-button-disabled-color) !important;
  pointer-events: none;
}
:deep(.subheader) {
  padding-bottom: var(--jh-space-100);
  padding-top: var(--jh-space-600);
}

@media (max-width: 468px) {
  jha-flex-wrapper {
    flex-wrap: wrap;
    gap: 0px;
  }
  jha-form-row {
    display: block;
  }

  jha-flex-wrapper > * {
    flex-basis: 100%;
  }
}
</style>
