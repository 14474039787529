<script lang="ts">
import { $inj, $injByInterface } from "@/common/decorators/depinject";
import {
  Dbounce,
  DocumentFactory,
  EnrollmentAccountApplicantFactory,
  EnrollmentAccountFactory,
  InfoDialogFactory,
  LookupFactory,
  LanguageFactory
} from "@/common/services/services.module";

import type IProductLookupFactory from "@/common/services/Lookup/IProductLookupFactory";
import { defineComponent } from "vue";
import { ProductSelectionConstant } from "@/common/constant/ProductSelectionConstant";
import type INotificationFactory from "@/common/services/INotificationFactory";
import type IEmpWorkspaceStore from "@/branchmanager/stores/IEmpWorkspaceStore";
import { cloneDeep } from "lodash";
import { BranchLanguageKeysConstant } from "@/branchmanager/app/constant/BranchLanguageKeysConstant";
import SLIDER_PANES from "@/common/enums/productSelectionSlides";
import PRODUCT_TYPES from "@/common/enums/productTypes";
import { DisplayConstant } from "@/common/constant/DisplayConstant";
import type { Detail, Validation } from "@/common/models/IStateValidationResponse";
import type { Product, ProductGroup, Account, Intention } from "@/common/models/IProductSelection";
import EmployeeWorkflowFactory from "@/branchmanager/services/EmployeeWorkflowFactory";
import EnrollmentValidationFactory from "@/branchmanager/services/EnrollmentValidationFactory";
import EnrollmentValidationStates from "@/branchmanager/app/enums/enrollmentValidationStates";
import BltEmpForm from "@/branchmanager/app/components/bltForm/bltEmpForm.vue";

export default defineComponent({
  name: "ProductSelectionState",
  components: { BltEmpForm },
  emits: ["showSearchBar", "afterLoad", "isSelectedAccountPane", "updateWorkspaceEntityValidation"],
  props: {
    showSearchIcon: {
      type: Boolean,
      default: false
    },
    filterProductGroups: {
      type: Array<ProductGroup>
    },
    isWorkspaceClosed: {
      type: Boolean
    },
    allProducts: {
      type: Array<Product>
    }
  },
  setup() {
    return {
      workspaceStore: $injByInterface<IEmpWorkspaceStore>("IWorkspaceStore"),
      productLookupFactory: $injByInterface<IProductLookupFactory>("IProductLookupFactory"),
      enrollmentAccountFactory: $inj(EnrollmentAccountFactory),
      enrollmentAccountApplicantFactory: $inj(EnrollmentAccountApplicantFactory),
      enrollmentValidationFactory: $inj(EnrollmentValidationFactory),
      notificationFactory: $injByInterface<INotificationFactory>("INotificationFactory"),
      documentFactory: $inj(DocumentFactory),
      dbounce: $inj(Dbounce),
      infoDialogFactory: $inj(InfoDialogFactory),
      lookupFactory: $inj(LookupFactory),
      workflowFactory: $inj(EmployeeWorkflowFactory),
      languageFactory: $inj(LanguageFactory)
    };
  },
  data() {
    return {
      errors: [] as Detail[],
      selectedPopupAccount: {} as Product | Record<string, never>,
      focusedPane: SLIDER_PANES.selectAccount.id as string,
      contentMarginClass: "contentspace-product-selection" as string,
      showInfoPopup: false as boolean,
      ProductSelectionConstant,
      loading: true as boolean,
      PRODUCT_TYPES,
      selectedProducts: [] as Account[],
      productGroups: [] as ProductGroup[],
      accountIntentions: [] as Intention[],
      showRemoveProductDialog: false as boolean,
      removableProduct: {} as Account,
      emptyData: ProductSelectionConstant.EMPTY_MESSAGE,
      OpenLanguageKeysConstant: BranchLanguageKeysConstant,
      selectedAccountTitle: "",
      confirmAccountTitle: ""
    };
  },
  async created() {
    Promise.all([
      this.getAccountList(),
      this.getProductGroups(),
      this.getIntention(),
      this.getScreen1Title(),
      this.getScreen2Title()
    ])
      .then((response) => {
        this.selectedProducts = response[0];
        this.productGroups = response[1];
        this.accountIntentions = response[2];
        this.selectedAccountTitle = response[3];
        this.confirmAccountTitle = response[4];
        //============to support filter and restore=====
        this.$emit("afterLoad", this.productGroups);
      })
      .then(() => {
        if (this.selectedProducts.length) {
          this.setFocusPane(SLIDER_PANES.confirmAccount.id);
        } else {
          this.setFocusPane(SLIDER_PANES.selectAccount.id);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    isLoading(): boolean {
      return this.loading;
    },
    accountChangeBtnLabel(): string {
      return this.selectedProducts?.length > 1 ? DisplayConstant.REMOVE : DisplayConstant.CHANGE;
    },
    isCurrentPaneTheSelectedAccount(): boolean {
      return this.focusedPane === SLIDER_PANES.selectAccount.id;
    },
    hasMoreThanOneProduct(): boolean {
      return (this.allProducts && this.allProducts?.length > 1) || this.selectedProducts?.length > 1;
    },
    productSelectionDescription(): string {
      return this.isCurrentPaneTheSelectedAccount ? "" : ProductSelectionConstant.CONFIRM_DESCRIPTION;
    },
    ProductSelectionTitle(): string {
      return this.isCurrentPaneTheSelectedAccount ? this.confirmAccountTitle : this.selectedAccountTitle;
    },
    hasError(): boolean {
      return this.workflowFactory.hasWorkflowError();
    },
    enrollmentId() {
      return this.workspaceStore.enrollment.enrollmentId
    },
    workspaceUUID() {
      return this.workspaceStore.workspaceUUID
    }
  },
  methods: {
    getIntention(): Promise<Intention[]> {
      return this.lookupFactory.Intention?.getAll({ enabled: true });
    },
    getProductGroups(): Promise<ProductGroup[]> {
      return this.productLookupFactory.getRegionalProducts(
        this.workspaceStore.workspaceUUID,
        this.workspaceStore.enrollment.enrollmentId
      );
    },
    getAccountList(): Promise<Account[]> {
      return this.enrollmentAccountFactory.getEnrollmentAccountList(
        this.workspaceStore.workspaceUUID,
        this.workspaceStore.enrollment.enrollmentId
      );
    },
    getScreen1Title(): Promise<string> {
      return this.languageFactory.get(this.OpenLanguageKeysConstant.BRANCH_PRODUCT_ACCOUNT_TITLE);
    },
    getScreen2Title(): Promise<string> {
      return this.languageFactory.get(this.OpenLanguageKeysConstant.BRANCH_PRODUCT_SELECT_TITLE);
    },
    addProduct(product: Product): void {
      this.loading = true;
      const message = cloneDeep({ ...product, accountIntention: ProductSelectionConstant.STANDARD });
      this.enrollmentAccountFactory
        .postEnrollmentAccount(this.workspaceStore.workspaceUUID, this.workspaceStore.enrollment.enrollmentId, message)
        .then(() => {
          return this.enrollmentAccountFactory.getEnrollmentAccountList(
            this.workspaceStore.workspaceUUID,
            this.workspaceStore.enrollment.enrollmentId
          );
        })
        .then((response) => {
          this.selectedProducts = response;
          this.$emit("updateWorkspaceEntityValidation");
        })
        .finally(() => (this.loading = false));
    },
    updateAccount(account: Account, event?: { detail: string }): void {
      if (event?.detail) {
        account.accountIntention = event.detail;
      }
      this.enrollmentAccountFactory
        .putEnrollmentAccount(this.workspaceStore.workspaceUUID, this.workspaceStore.enrollment.enrollmentId, account)
        .then(() => {
          this.$emit("updateWorkspaceEntityValidation");
        });
    },
    removeProduct(product: Account): void {
      this.loading = true;
      if (this.enrollmentAccountApplicantFactory.accountContainsAnyApplicant(product)) {
        this.removableProduct = product;
        this.showRemoveProductDialog = true;
      } else {
        this.enrollmentAccountFactory
          .deleteEnrollmentAccount(
            this.workspaceStore.workspaceUUID,
            this.workspaceStore.enrollment.enrollmentId,
            product.accountId
          )
          .then(() => {
            return this.enrollmentAccountFactory.getEnrollmentAccountList(
              this.workspaceStore.workspaceUUID,
              this.workspaceStore.enrollment.enrollmentId
            );
          })
          .then((response) => {
            this.selectedProducts = response;
            this.$emit("updateWorkspaceEntityValidation");
          })
          .finally(() => (this.loading = false));
      }
    },
    onConfirmRemoveProduct(): void {
      this.enrollmentAccountApplicantFactory
        .deleteAllAccountApplicant(
          this.workspaceStore.workspaceUUID,
          this.workspaceStore.enrollment.enrollmentId,
          this.removableProduct
        )
        .then(() => {
          this.removeProduct(this.removableProduct);
        });
    },
    async showDialog(product: Product): Promise<void> {
      product.disclosureContent = await this.productLookupFactory.getProductInfo(JSON.stringify(product.productId));
      this.selectedPopupAccount = product;
      this.showInfoPopup = true;
    },
    async deleteEnrollmentAccount(account: Account): Promise<void> {
      account.deleting = this.dbounce.create(150);
      await this.enrollmentAccountApplicantFactory.deleteAllAccountApplicant(
        this.workspaceStore.workspaceUUID,
        this.workspaceStore.enrollment.enrollmentId,
        account
      );
      await this.enrollmentAccountFactory.deleteEnrollmentAccount(
        this.workspaceStore.workspaceUUID,
        this.workspaceStore.enrollment.enrollmentId,
        account.accountId
      );
      const response = await this.enrollmentAccountFactory.getEnrollmentAccountList(
        this.workspaceStore.workspaceUUID,
        this.workspaceStore.enrollment.enrollmentId
      );
      this.selectedProducts = response;
      account.deleting.finish();
    },
    setFocusPane(focusPane: string): void {
      this.focusedPane = focusPane;
      this.$emit("isSelectedAccountPane", this.isCurrentPaneTheSelectedAccount);
    },
    selectAccount(product: Product): void {
      this.setFocusPane(SLIDER_PANES.confirmAccount.id);
      this.addProduct(product);
    },
    async changeAccount(account: Account): Promise<void> {
      await this.deleteEnrollmentAccount(account);
      if (this.selectedProducts.length < 1) {
        this.setFocusPane(SLIDER_PANES.selectAccount.id);
      }
    },
    navigateToHomeScreen(): void {
      this.setFocusPane(SLIDER_PANES.selectAccount.id);
    },
    closeInfoPopup(): void {
      this.showInfoPopup = false;
      this.selectedPopupAccount = {};
    },
    onShowSearchBar(value: boolean): void {
      this.$emit("showSearchBar", value);
    }
  }
});
</script>
<template>
  <div>
    <blt-emp-form
      :searchIcon="isCurrentPaneTheSelectedAccount && showSearchIcon"
      @searchBtnClicked="onShowSearchBar"
      :innerScreenButtonEnabled="!isCurrentPaneTheSelectedAccount"
      :hideContinueButton="isCurrentPaneTheSelectedAccount"
      :innerScreenBtnFunc="navigateToHomeScreen"
      :bltUiText="ProductSelectionTitle"
      :description="productSelectionDescription"
      :contentMarginClass="contentMarginClass"
      :loading="isLoading"
      :alternateBackFunc="navigateToHomeScreen"
      hideContinueLoader
      :workspace-uuid="workspaceUUID"
      :enrollment-id="enrollmentId"
      validation="PRODUCT_SELECTION"
      validation-type="ENROLLMENT">
      <slot name="searchfilter"></slot>
      <jha-slider v-if="filterProductGroups?.length">
        <jha-slider-content :focusedPane="focusedPane" class="pane-height">
          <jha-slider-pane select-account focus>
            <div class="product-list" v-for="accounts in filterProductGroups" :key="accounts.typeCode">
              <div class="content-heading" v-if="accounts.products.length">{{ accounts.typeDescription }}</div>
              <jha-list>
                <jha-list-item class="list-align" v-for="account in accounts.products" :key="account.productId">
                  <button type="button" button-reset @click="selectAccount(account)" :disabled="isWorkspaceClosed">
                    <jha-flex-wrapper space-between>
                      <jha-icon-check class="icon-pos" v-if="account.group === PRODUCT_TYPES.CHECKING"></jha-icon-check>
                      <jha-icon-piggy-bank
                        class="icon-pos"
                        primary=""
                        v-if="account.group === PRODUCT_TYPES.SAVINGS"
                      ></jha-icon-piggy-bank>
                      <jha-icon-date class="icon-pos" v-if="account.group === PRODUCT_TYPES.CD"></jha-icon-date>
                      <div class="list-text jha-icon-pad pl-text">
                        <span>{{ account.description }}</span>
                        <span v-if="account.disclosureCode" class="info-icon">
                          <jha-icon-information
                            small
                            primary=""
                            class="jh-icon-pos secondary-fill-color"
                            @click.stop="showDialog(account)"
                          >
                          </jha-icon-information>
                        </span>
                      </div>
                      <jha-icon-chevron-forward small class="chevron-forward"></jha-icon-chevron-forward>
                    </jha-flex-wrapper>
                  </button>
                </jha-list-item>
              </jha-list>
            </div>
          </jha-slider-pane>

          <jha-slider-pane confirm-account class="ps-min-height">
            <jha-list class="confirm-account-list">
              <jha-list-item
                class="list-details list-align"
                v-for="account in selectedProducts"
                :key="account.accountId"
              >
                <div class="d-flex space-between">
                  <div class="left-sec">
                    <jha-flex-wrapper>
                      <div class="m-tb-auto">
                        <jha-icon-check
                          class="icon-pos"
                          v-if="account.accountGroup === PRODUCT_TYPES.CHECKING"
                        ></jha-icon-check>

                        <jha-icon-piggy-bank
                          class="icon-pos"
                          v-if="account.accountGroup === PRODUCT_TYPES.SAVINGS"
                        ></jha-icon-piggy-bank>

                        <jha-icon-date
                          class="icon-pos"
                          v-if="account.accountGroup === PRODUCT_TYPES.CD"
                        ></jha-icon-date>
                      </div>
                      <div class="product-type">
                        <div class="list-text primary-text">{{ account.accountMinorDescription }}</div>
                        <div class="secondary-text pt-2" :class="{ 'no-change-button': !hasMoreThanOneProduct }">
                          {{ account.accountMajorDescription }}
                        </div>
                      </div>
                    </jha-flex-wrapper>
                  </div>
                  <div class="right-sec">
                    <jha-button
                      link
                      type="button"
                      class="change-btn"
                      button-reset
                      @click="changeAccount(account)"
                      :disabled="isWorkspaceClosed"
                      v-if="hasMoreThanOneProduct"
                    >
                      <div class="open prod-change">{{ accountChangeBtnLabel }}</div>
                    </jha-button>
                  </div>
                </div>
                <jha-flex-wrapper class="accountControls">
                  <jha-form-select
                    outline
                    name="accountIntention"
                    :valueKey="'typeCode'"
                    :labelKey="'typeDescription'"
                    v-model="account.accountIntention"
                    @change="updateAccount(account, $event)"
                    v-blt-ui-text-ex="OpenLanguageKeysConstant.OPEN_PRODUCT_INPUT_INTENTION"
                    :options="accountIntentions"
                    :disabled="isWorkspaceClosed"
                  >
                  </jha-form-select>
                  <jha-form-text-input
                    outline
                    name="accountIntentionDescription"
                    v-model="account.description"
                    v-blt-ui-text-ex="OpenLanguageKeysConstant.OPEN_PRODUCT_INPUT_ACCOUNT_DESCRIPTION"
                    @blur="updateAccount(account)"
                    :disabled="isWorkspaceClosed"
                    :autocomplete="false"
                  >
                  </jha-form-text-input>
                </jha-flex-wrapper>
              </jha-list-item>
              <jha-button
                link
                sync
                type="button"
                class="change-btn pl-16 pr-16"
                button-reset
                @click="navigateToHomeScreen"
                :disabled="isWorkspaceClosed"
              >
                <jha-icon-plus slot="icon-left" />
                <span class="font-weight-500">{{ ProductSelectionConstant.ADD_ANOTHER_ACCOUNT }}</span>
              </jha-button>
            </jha-list>
          </jha-slider-pane>
        </jha-slider-content>
      </jha-slider>
      <bltEmptyData :bltText="emptyData" v-else-if="!isLoading">
        <template v-slot:jhaIcon>
          <jha-icon-search class="secondary-fill-color"></jha-icon-search>
        </template>
      </bltEmptyData>
      <bltModalDialog
        :title="selectedPopupAccount.description"
        :showDialog="showInfoPopup"
        @cancel="closeInfoPopup"
        :showFooter="false"
        type="large-dialog"
      >
        <template #dialog-content>
          <iframe
            title=""
            class="iframe"
            v-blt-iframe="{
              html: selectedPopupAccount.disclosureContent,
              disclosureStatus: ProductSelectionConstant.COMPLETE
            }"
          >
          </iframe>
        </template>
      </bltModalDialog>

      <bltModalDialog
        type="small-dialog"
        :showDialog="showRemoveProductDialog"
        :title="ProductSelectionConstant.REMOVE_DIALOG_TITLE"
        :messageBody="ProductSelectionConstant.REMOVE_DIALOG_CONTENT"
        showConfirm
        :confirmLabel="ProductSelectionConstant.REMOVE_DIALOG_CONFIRM"
        @confirm="onConfirmRemoveProduct"
        showCancel
        :cancelLabel="ProductSelectionConstant.REMOVE_DIALOG_CANCEL"
        @cancel="showRemoveProductDialog = false"
      ></bltModalDialog>
    </blt-emp-form>
  </div>
</template>
<style scoped>
@import '../../../../assets/spacing.css';

.icon-pos {
  height: 24px;
  width: 24px;
  fill: var(--jha-color-primary);
}
.product-list jha-list {
  margin-bottom: var(--jh-space-400);
}
.product-list:last-child jha-list {
  margin-bottom: var(--jh-space-200);
}
.change-btn {
  width: max-content;
}
.pl-text {
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
}

.confirm-account-list .left-sec {
  flex: 1;
}
.jh-icon-pos {
  width: 20px;
  height: 20px;
  vertical-align: top;
}
:deep(.subheader) {
  padding-bottom: var(--jh-space-400);
}
:deep(.footer-content) {
  padding-top: 0;
}
:deep(.change-btn > button) {
  display: contents;
}

.font-weight-500 {
  font-weight: 500;
}

.list-details {
  padding-left: 0px;
}
.jha-icon-search-color-active {
  fill: var(--body-text-secondary-color);
}

jha-list-item:hover {
  background: none;
  cursor: pointer;
}
.chevron-forward {
  fill: var(--body-text-secondary-color);
}

.content-heading {
  padding-top: 0px;
  padding-bottom: 0px;
}
.iframe {
  width: 100%;
  border: 0px;
  margin-bottom: 0px;
  min-height: calc(100vh - 180px);
  padding: 24px;
}
footer {
  flex-wrap: wrap;
}
.btn {
  width: 100%;
  margin-bottom: 10px;
}
jha-button .btn-border button {
  border: none;
}
.info-icon {
  padding-left: var(--jh-space-100);
}
.pane-height {
  min-height: 80px !important;
}

.ps-min-height {
  min-height: 80px;
}

.no-change-button {
  padding-top: var(--jh-space-200);
}

.pt-2 {
  padding-top: var(--jh-space-50);
}

:deep(.footer-content > div > jha-button) {
  margin-bottom: var(--jh-space-200);
}
:deep(.footer-content > div > .btn-border) {
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 0px;
}

.accountControls {
  gap: 8px;
  margin-top: 4px;
  padding: 0px 0px 12px 32px;
}
.accountControls > * {
  flex: 1;
  margin-bottom: 0px;
}
.list-details:not(:first-child) {
  padding-top: 32px;
}

@media (max-width: 468px) {
  .iframe {
    width: 99%;
    min-height: calc(100vh - 112px);
  }
  .banno-web-dialog-content-wrapper {
    overflow: hidden;
  }
  .accountControls {
    flex-direction: column;
    gap: 4px;
  }
  .accountControls > * {
    width: 100%;
  }
  .list-align {
    padding-right: 0px;
  }
}
@media (max-width: 468px) {
   .iframe {
    padding: 16px;
   }
}
</style>
