import type IRouterService from "@/common/services/utils/IRouterService";
import { injectable } from "inversify";
import type { NavigationFailure, RouteLocationRaw, RouteRecordNormalized } from "vue-router";
import router from "./index";

@injectable()
class RouterService implements IRouterService {
  currentRoute: { value: any } = router.currentRoute;

  push(route: RouteLocationRaw): Promise<void | NavigationFailure | undefined> {
    return router.push(route);
  }

  getQuery(key: string): string | null {
    const uri = window.location.search.substring(1);
    const params = new URLSearchParams(uri);
    return params.get(key);
  }

  go(index: number): void {
    return router.go(index);
  }

  getRoutes(): RouteRecordNormalized[] {
    return router.getRoutes();
  }
}
export default RouterService;
