<script setup lang="ts">

import { ref } from "vue";
import ShadowDomService from "@/common/components/bltForm/shadowDomService";
import { $inj } from "@/common/decorators/depinject";

defineProps<{
  formStyle?: {[key: string]: string}
}>()

const shadowDomService = $inj(ShadowDomService);

const emit = defineEmits(["submit", "validSubmit", "invalidSubmit"]);

const blurEvent = new Event("blur");

const form = ref<HTMLFormElement>();

function handleSubmit() {

  const formElem = form.value;

  if (formElem === undefined) {
    console.error("An error occurred during BltForm submission, expected a form element to exist, but it was undefined.");
    return;
  }

  const inputs: HTMLInputElement[] = shadowDomService.querySelectorAll("input,select,textarea", formElem);

  const isValid = inputs.every(it => it.validity.valid);

  inputs.forEach(it => it.dispatchEvent(blurEvent));

  if(isValid) {
    emit('validSubmit')
  } else {
    emit('invalidSubmit')
  }

  emit('submit')

}

defineExpose({form})
</script>

<template>
  <form
      :style="formStyle"
      @submit.prevent.stop="handleSubmit"
      ref="form">
    <slot />
  </form>
</template>

<style scoped>

</style>