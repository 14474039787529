<script lang="ts">
import { defineComponent } from "vue";
import { $inj, $injByInterface } from "@/common/decorators/depinject";
import { EnrollmentServiceFactory, Base64Factory } from "@/common/services/services.module";
import type IEmpWorkspaceStore from "@/branchmanager/stores/IEmpWorkspaceStore";
import { cloneDeep, isEmpty } from "lodash";
import bltEmptyData from "@/common/components/bltEmptyData/bltEmptyData.vue";
import type {
  IApplicantService,
  IProductHQImage,
  IProductsThumbnail,
} from "@/common/models/IService";
import type IWorkspace from "@/common/models/IWorkspace";
import STATE_MSG from "@/common/messages/state.messages";

export default defineComponent({
  name: "bltEmpProductImages",
  emits: ["closeCardImageModal", "saveCardImageModal"],
  components: {
    bltEmptyData
  },
  props: {
    products: {
      type: Object as () => IApplicantService,
      required: true
    },
    showChooseCardImage: {
      type: Boolean,
      required: true
    },
    modalClass: {
      type: String
    },
    headerTitle: {
      type: String
    }
  },
  data() {
    return {
      selectedImage: {} as IProductHQImage,
      bltProduct: {} as IApplicantService,
      isFullScreenImage: false as boolean,
      isEnableSaveCardImage: true as boolean,
      isCardImageLoader: true as boolean,
      emptyData: STATE_MSG.EMP_SERVICES.NO_PRODUCT_IMAGE_FOUND,
      headerDescription: STATE_MSG.EMP_SERVICES.SELECT_CARD_IMAGE_DESC,
      STATE_MSG
    };
  },
  setup() {
    const enrollmentServiceFactory = $inj(EnrollmentServiceFactory);
    const workspaceStore = $injByInterface<IEmpWorkspaceStore>("IWorkspaceStore");
    const base64Factory = $inj(Base64Factory);
    return {
      enrollmentServiceFactory,
      workspaceStore,
      base64Factory
    };
  },
  computed: {
    currentWorkspace(): IWorkspace {
      return this.workspaceStore.workspace;
    },
    currentWorkspaceUID(): string {
      return this.workspaceStore.workspace.workspaceUUID;
    },
    currentEnrollmentId(): number {
      return this.workspaceStore.enrollment.enrollmentId;
    },
    isProductImages(): number {
      return this.bltProduct.images && this.bltProduct.images.length;
    },
    isHQImage(): boolean {
      return this.selectedImage.HQImage ? true : false;
    },
    isSelectedImage(): number {
      return Object.keys(this.selectedImage).length;
    },
    productImages(): IProductHQImage[] {
      return this.bltProduct.images;
    },
    selectetdImageEncodedBase64(): string {
      return this.isHQImage ? this.selectedImage?.HQImage?.encodedBase64 : this.selectedImage.thumb.encodedBase64;
    },
    activeProductAccountServiceId(): number{
      return this.bltProduct.accountServiceId;
    }
  },
  methods: {
    saveCardImageModal(): void {
      this.bltProduct.images = [] as IProductHQImage[];
      this.bltProduct.imageId = this.selectedImage.imageId;
     const selectedImageObj:IProductsThumbnail = {
        product: this.bltProduct,
        thumbnail: this.base64Factory.getFromUnencoded(
          this.selectedImage.thumb.imageDataBase64,
          this.selectedImage.thumb.imageFormat
        )
      };
      this.$emit("saveCardImageModal", selectedImageObj);
      this.isFullScreenImage = false;
      this.isEnableSaveCardImage = true;
      this.selectedImage = {} as IProductHQImage;
      this.bltProduct = {} as IApplicantService;
    },
    closeCardImageModal(): void {
      if (this.selectedImage) {
        this.selectedImage = {} as IProductHQImage;
      }
      this.$emit("closeCardImageModal");
    },
    loadFullScreen(image: IProductHQImage): Promise<void> {
      this.selectedImage = image;
      return this.enrollmentServiceFactory
        .getProductImageHQ(this.currentWorkspaceUID, this.currentEnrollmentId, this.bltProduct, image.imageId)
        .then((response) => {          
          this.isFullScreenImage = true;
          this.isEnableSaveCardImage = false;
          image.HQImage = response;
        });
    },
    onInit(): void {
      this.isCardImageLoader = true;
      this.selectedImage = {} as IProductHQImage;
      this.isFullScreenImage = false;
      this.enrollmentServiceFactory
        .getProductImageId(this.currentWorkspaceUID, this.currentEnrollmentId, this.activeProductAccountServiceId)
        .then((response) => {
          this.bltProduct.images = response;
          if (!isEmpty(this.bltProduct.images)) {
            return Promise.all(
              this.bltProduct.images.map((image: IProductHQImage) => {
                image.accountServiceCode = this.bltProduct.accountServiceCode;
                image.productCode = this.bltProduct.productCode;

                return this.enrollmentServiceFactory
                  .getProductThumbnail(
                    this.currentWorkspaceUID,
                    this.currentEnrollmentId,
                    this.activeProductAccountServiceId,
                    image.imageId
                  )
                  .then((thumb) => {
                    this.isCardImageLoader = false;
                    image.thumb = thumb;
                    return image;
                  });
              })
            );
          } else {
            this.isCardImageLoader = false;
          }
        });
    },
    innerScreenBackBtn(): void {
      this.isFullScreenImage = false;
      this.isEnableSaveCardImage = true;
      this.selectedImage = {} as IProductHQImage;
    }
  },
  watch: {
    products(newVal): void {
      this.bltProduct = cloneDeep(newVal);
      if (!isEmpty(this.bltProduct)){
        this.onInit();
      }
    }
  }
});
</script>

<template>
  <blt-modal-dialog
    :showDialog="showChooseCardImage"
    :title="headerTitle"
    showConfirm
    :confirmLabel="STATE_MSG.EMP_SERVICES.PRODUDCT_SAVE_BUTTON"
    @cancel="closeCardImageModal"
    @confirm="saveCardImageModal"
    @backButton="innerScreenBackBtn"
    :innerScreenBackBtn="isFullScreenImage"
    :confirmDisabled="isEnableSaveCardImage"
    :stateClass="modalClass"
    :smallDialog="true"
    type="small-dialog"
  >
    <template #dialog-content>
      <blt-contentloader :contentLoader="isCardImageLoader"></blt-contentloader>
      <div class="dialog-content" v-if="!isCardImageLoader">
        <div class="product-card-block" v-if="!isProductImages">
          <bltEmptyData :bltText="emptyData">
            <template v-slot:jhaIcon>
              <jha-icon-credit-card class="secondary-fill-color"></jha-icon-credit-card>
            </template>
          </bltEmptyData>
        </div>
        <div v-if="isProductImages">
          <div v-if="!isFullScreenImage">
            <div class="regular-text mt-24 mb-16">{{ headerDescription }}</div>
            <div class="card-dp" v-if="productImages && !isSelectedImage">
              <div class="flex-gap">
                <div
                  class="card-images"
                  v-for="(image, index) in productImages"
                  :key="image.imageId"
                  @click.stop="loadFullScreen(image)"
                  @keydown.enter.stop="loadFullScreen(image)"
                  :class="{ 'hide-bg': image && image?.thumb?.encodedBase64 }"
                  :tabindex="index"
                >
                  <img :src="image?.thumb?.encodedBase64" alt="debit card image" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="isFullScreenImage">
            <div class="selected-card-placeholder" v-if="isSelectedImage">
              <img
                class="fullSizeImage"
                :src="selectetdImageEncodedBase64"
                alt="Selected image (Thumbnail)"
                v-if="!isHQImage"
              />
              <img
                class="fullSizeImage"
                :src="selectetdImageEncodedBase64"
                alt="Selected image (Full-size)"
                v-if="isHQImage"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </blt-modal-dialog>
</template>
<style scoped>
.dialog-content {
  padding: 0px var(--jh-space-600);
}

.regular-text {
  font-size: 14px;
  font-weight: 400;
}

.flex-gap {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 48px;
  justify-content: space-between;
}

.card-images {
  border-radius: 3px;
  margin-bottom: 24px;
  cursor: pointer;
}

.selected-card-placeholder {
  border-radius: 10px;
  margin: 0 auto;
  padding: 24px 0px;
}

.fullSizeImage {
  width: 100%;
}

.product-card-block {
  padding-top: var(--jh-space-900);
  padding-bottom: var(--jh-space-900);
  padding-right: var(--jh-space-600);
}

.product-card-detail-block {
  line-height: 20px;
  font-weight: 500;
  display: block;
}

.empty-state {
  text-align: center;
}

.hide-bg {
  background: transparent;
}

@media only screen and (max-width: 599px) {
  .dialog-content {
    padding: 0px var(--jh-space-400);
  }
  .flex-gap {
    gap: inherit;
    justify-content: space-between;
  }
}
</style>
