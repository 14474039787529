import type HttpHandlerConfig from "@/common/interfaces/httpHandler/HttpHandlerConfig";
import type AxiosRequestInterceptor from "@/common/interfaces/axios/AxiosRequestInterceptor";
import type AxiosResponseInterceptor from "@/common/interfaces/axios/AxiosResponseInterceptor";
import CommonRequestInterceptor from "@/common/interceptors/CommonRequestInterceptor";
import EmployeeChannelResponseInterceptor from "@/branchmanager/interceptors/EmployeeChannelResponseInterceptor";
import EmployeeChannelRequestInterceptor from "@/branchmanager/interceptors/EmployeeChannelRequestInterceptor";
import { injectable } from "inversify";
import CommonResponseInterceptor from "@/common/interceptors/CommonResponseInterceptor";

@injectable()
export default class EmployeeChannelHttpHandlerConfig implements HttpHandlerConfig {

  requestInterceptors: AxiosRequestInterceptor[] = [
    new CommonRequestInterceptor(),
    new EmployeeChannelRequestInterceptor(),
  ]

  responseInterceptors: AxiosResponseInterceptor[] = [
    new CommonResponseInterceptor(),
    new EmployeeChannelResponseInterceptor(),
  ]

}