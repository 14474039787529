import { ApplicantConstant } from "@/common/constant/ApplicantConstant";
import { TabConstants } from "@/common/constant/TabConstants";
import { $injByInterface } from "@/common/decorators/depinject";
import type IWorkspaceStore from "@/common/services/Workspace/IWorkspaceStore";
import {
  EnrollmentAccountFactory,
  EnrollmentFactory,
  LookupFactory,
  TabFactory
} from "@/common/services/services.module";
import { inject, injectable } from "inversify";
import type IWorkspaceTabEntityFactory from "./IWorkspaceTabEntityFactory";
import DEPENDENCYTYPES from "@/common/dependency.types";
import type IEmpWorkspaceStore from "../stores/IEmpWorkspaceStore";
import {Service} from "@/common/dependency.configs";
import type {Detail} from "@/common/models/IStateValidationResponse";
import type IWfState from "@/common/models/WfState";

@injectable()
@Service
class WorkspaceTabEnrollmentFactory implements IWorkspaceTabEntityFactory {
  constructor(
    @inject(EnrollmentFactory) private enrollmentFactory: EnrollmentFactory,
    @inject(EnrollmentAccountFactory) private enrollmentAccountFactory: EnrollmentAccountFactory,
    @inject(TabFactory) private tabFactory: TabFactory,
    @inject(LookupFactory) private lookupFactory: LookupFactory,
    @inject(DEPENDENCYTYPES.IWorkspaceStore) private workspaceStore: IEmpWorkspaceStore
  ) {}
  getWorkspaceType(): string {
    return this.workspaceStore.workspace.workspaceType;
  }
  getWorkflowTitle(workspaceType: string): string {
    return ApplicantConstant.ENROLLMENT_WORKFLOW;
  }

  getEntity(workspaceUUID: string, entityId: number): Promise<any> {
    return this.enrollmentFactory.getEnrollment(workspaceUUID, entityId);
  }
  getEntityAccountList(workspaceUUID: string, entityId: number): Promise<any> {
    return this.enrollmentAccountFactory.getEnrollmentAccountList(workspaceUUID, entityId);
  }
  getEntityValidation(workspaceUUID: string, entityId: number): Promise<any> {
    return this.enrollmentFactory.getEnrollmentValidation(workspaceUUID, entityId);
  }
  getEntityInfo(workspaceType: string, workspaceUUID: string, entityId: number): Promise<any> {
    return Promise.resolve({});
  }
  getEntityTabs(workspaceType: string): Promise<Array<IWfState>> {
    return this.tabFactory.getTabsFromWorkflow(TabConstants.ENROLLMENT, workspaceType);
  }
  getEntityState(entity: any): string {
    return entity.enrollmentState;
  }

  async getWindowTitle(workspaceType: string, info: any) {
    if (info) {
      const enrollmentTypes = await this.lookupFactory.Enrollment?.getAllFlat("typeCode", {});
      return enrollmentTypes[info.enrollmentType].typeDescription;
    } else return "Enrollment";
  }

  getLockNotifications(): { message: string; type: string }[] {
    return [];
  }
  getExistingEntityMessage(): { message: string; type: string }[] {
    return [];
  }

  setEntityToStore(workspaceUUID: string, enrollmentId: number): Promise<any> {
    const workspaceStore = $injByInterface<IWorkspaceStore>("IWorkspaceStore");
    return this.enrollmentFactory.getEnrollment(workspaceUUID, enrollmentId).then((enrollment) => {
      return (workspaceStore.enrollment = enrollment);
    });
  }

  updateWorkspaceEntityState(workspaceUUID: string, enrollmentId: number, state: string): Promise<any> {
    const workspaceStore = $injByInterface<IWorkspaceStore>("IWorkspaceStore");
    if (Number(enrollmentId) === workspaceStore.enrollment.enrollmentId) {
      return this.enrollmentFactory.updateEnrollmentState(workspaceUUID, enrollmentId, state);
    }
    return Promise.resolve();
  }
}
export default WorkspaceTabEnrollmentFactory;
