import ResponseTypes from "@/common/enums/responseTypesEnum";
import { inject, injectable } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import type EnrollmentValidationStates from "@/branchmanager/app/enums/enrollmentValidationStates";
import type { Validation } from "@/common/models/IStateValidationResponse";
import { Service } from "@/common/dependency.configs";

@Service
@injectable()
class EnrollmentValidationFactory {
  constructor(
    @inject(HttpHandler) private httpHandler: HttpHandler
  ) {}

  private ALL_VALIDATIONS = "ALL"

  async getForState(workspaceUUID: string, enrollmentId: number, state: keyof typeof EnrollmentValidationStates): Promise<Validation> {
    const validations = await this.request(workspaceUUID, enrollmentId, state);
    if(validations.length === 1) {
      return validations[0];
    } else {
      throw new Error(`Unexpectedly received multiple validation responses for ${state}`)
    }
  }

  async getAll(workspaceUUID: string, enrollmentId: number, state: keyof typeof EnrollmentValidationStates): Promise<Validation[]> {
    return this.request(workspaceUUID, enrollmentId, state);
  }

  private async request(workspaceUUID: string, enrollmentId: number, state: string): Promise<Validation[]> {
    return await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/validate?type=${state}`,
      {},
      ResponseTypes.Payload
    );
  }

}
export default EnrollmentValidationFactory;
