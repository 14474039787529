import type AxiosRequestInterceptor from "@/common/interfaces/axios/AxiosRequestInterceptor";
import type { AxiosRequestConfig } from "axios";
import type CacheableAxiosRequestConfig from "@/common/services/connect/CacheableAxiosRequestConfig";

export default class EmployeeChannelRequestInterceptor implements AxiosRequestInterceptor {
  onFulfilled<T>(config: AxiosRequestConfig<T>): CacheableAxiosRequestConfig | Promise<CacheableAxiosRequestConfig> {
    return config;
  }

  onRejected(error: unknown): unknown {
    return Promise.reject(error);
  }

}