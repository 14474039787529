<template>
  <div v-if="applicant" class="current-applicant-wrapper">
    <div
      class="current-applicant-header"
      :class="{ 'current-applicant-header-email': applicantEmail, 'current-applicant-variation': inline }"
    >
      <jha-flex-wrapper space-between>
        <div class="d-flex flex1">
          <div class="applicant-icons m-tb-auto">
            <div v-if="applicant" class="pr-16">
              <jha-icon-user class="center-pos" v-if="applicant.applicantType === PERSON"></jha-icon-user>
              <jha-icon-business
                class="business-icon"
                v-else-if="applicant.applicantType === BUSINESS"
              ></jha-icon-business>
            </div>
            <div v-else>
              <jha-icon-circle-filled></jha-icon-circle-filled>
            </div>
          </div>

          <div class="applicant-details flex1">
            <div class="primary-text">
              {{ applicantName }}
              <span v-if="inline && applicantEmail">-</span>
            </div>
            <div class="secondary-text">
              {{ applicantEmail }}
              <span v-if="applicantId">(Applicant #: {{ applicantId }})</span>
              <span v-if="coreId">
                (<span v-blt-ui-text="'branch_summary_core_id_prefix'"></span>
                  &nbsp;{{ coreId }})
              </span>
            </div>
          </div>
          <slot name="right-section">
            <div class="status" :class="{ complete: isAccountComplete }" v-if="status">
              <jha-icon-checkmark class="jha-icon-checkmark-success" v-if="isAccountComplete"> </jha-icon-checkmark>
              <span :class="{ 'complete-txt': isAccountComplete }">{{ status }}</span>
            </div>
          </slot>
        </div>
      </jha-flex-wrapper>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { $inj } from "@/common/decorators/depinject";
import FullApplicantNameFilter from "@/common/services/filters/fullApplicantNameFilter";
import WORKFLOW_TYPES from "@/common/enums/workflowTypesEnum";
import { DisplayConstant } from "@/common/constant/DisplayConstant";
import "@jack-henry/jh-ui/components/progress/progress.js";

export default defineComponent({
  name: "bltCurrentApplicantWithStatus",
  props: {
    applicant: {
      type: Object,
      required: true
    },
    status: {
      type: String,
      required: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    applicantId: {
      type: String
    },
    coreId: {
      type: String
    },
    titleSuffix: {
      type: String,
      required: false,
      default: ''
    }
  },

  setup() {
    const fullApplicantNameFilter = $inj(FullApplicantNameFilter);
    return {
      fullApplicantNameFilter
    };
  },
  data() {
    return { PERSON: WORKFLOW_TYPES.PERSON, BUSINESS: WORKFLOW_TYPES.BUSINESS };
  },
  computed: {
    isAccountComplete(): boolean {
      return this.status === DisplayConstant.COMPLETE_MSG;
    },
    applicantEmail(): string {
      return (this.applicant?.person || this.applicant?.business) && this.applicant.emails?.length
        ? this.applicant.emails[0].emailAddress
        : "";
    },
    applicantName(): string {
      return this.applicant && this.applicant?.applicantType
        ? this.fullApplicantNameFilter.fullApplicantName(this.applicant) + this.titleSuffix
        : "";
    }
  }
});
</script>

<style scoped>
.current-applicant-wrapper {
  width: 100%;
}

.status {
  color: var(--jha-text-light);
}

.pending {
  font-size: 12px;
  padding-right: var(--jh-space-200);
  color: var(--body-text-secondary-color);
  vertical-align: middle;
}

.complete {
  font-size: 12px;
  color: var(--jha-color-success);
  vertical-align: middle;
}

.complete-txt {
  padding-right: var(--jh-space-200);
  padding-left: var(--jh-space-100);
}

.jha-icon-checkmark-success {
  fill: var(--jha-color-success);
}

.jha-icon-checkmark-warning {
  fill: var(--jha-color-warning);
}

jha-icon-checkmark {
  vertical-align: middle;
}
.current-applicant-header {
  padding: var(--jh-space-300) var(--jh-space-400);
  margin: var(--jh-space-500) var(--jh-space-500) var(--jh-space-400) var(--jh-space-500);
  background-color: var(--jha-background-color);
  border-radius: var(--jh-space-100);
}
.current-applicant-header-email {
  padding: var(--jh-space-200) var(--jh-space-400);
}
.center-pos {
  vertical-align: middle;
}
.business-icon{
  vertical-align: middle;
  width:20px;
  height:20px;
}
@media (max-width: 599px) {
  .current-applicant-header {
    margin-right: var(--jh-space-300);
    margin-left: var(--jh-space-300);
    padding: var(--jh-space-300) var(--jh-space-400);
  }
}
</style>
