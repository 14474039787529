//icons
import "@banno/jha-wc/src/icons/jha-icon-menu.js";
import "@banno/jha-wc/src/icons/jha-icon-clock.js";
import "@banno/jha-wc/src/icons/jha-icon-chevron-back.js";
import "@banno/jha-wc/src/icons/jha-icon-chevron-forward.js";
import "@banno/jha-wc/src/icons/jha-icon-user.js";
import "@banno/jha-wc/src/icons/jha-icon-business.js";
import "@banno/jha-wc/src/icons/jha-icon-delete.js";
import "@banno/jha-wc/src/icons/jha-icon-wallet.js";
import "@banno/jha-wc/src/icons/jha-icon-chevron-down.js";
import "@banno/jha-wc/src/icons/jha-icon-chevron-up.js";
import "@banno/jha-wc/src/icons/jha-icon-lock.js";
import "@banno/jha-wc/src/icons/jha-icon-dashboard.js";
import "@banno/jha-wc/src/icons/jha-icon-settings.js";
import "@banno/jha-wc/src/icons/jha-icon-logout.js";
import "@banno/jha-wc/src/icons/jha-icon-mail.js";
import "@banno/jha-wc/src/icons/jha-icon-receipt.js";
import "@banno/jha-wc/src/icons/jha-icon-warning.js";
import "@banno/jha-wc/src/icons/jha-icon-arrow-back";
import "@banno/jha-wc/src/icons/jha-icon-view-more.js";
import "@banno/jha-wc/src/icons/jha-icon-fi.js";
import "@banno/jha-wc/src/icons/jha-icon-checkmark.js";
import "@banno/jha-wc/src/icons/jha-icon-check.js";
import "@banno/jha-wc/src/icons/jha-icon-piggy-bank.js";
import "@banno/jha-wc/src/icons/jha-icon-print.js";
import "@banno/jha-wc/src/icons/jha-icon-date.js";
import "@banno/jha-wc/src/icons/jha-icon-information.js";
import "@banno/jha-wc/src/icons/jha-icon-credit-card.js";
import "@banno/jha-wc/src/icons/jha-icon-edit.js";
import "@banno/jha-wc/src/icons/jha-icon-monitor.js";
import "@banno/jha-wc/src/icons/jha-icon-form.js";
import "@banno/jha-wc/src/icons/jha-icon-briefcase.js";
import "@banno/jha-wc/src/icons/jha-icon-circle-filled.js";
import "@banno/jha-wc/src/icons/jha-icon-circle-close.js";
import "@banno/jha-wc/src/icons/jha-icon-plus.js";
import "@banno/jha-wc/src/icons/jha-icon-sms.js";
import "@banno/jha-wc/src/icons/jha-icon-link.js";
import "@banno/jha-wc/src/icons/jha-icon-print.js";

//import all your banno components here so that you do not require to import in every vue component.
import "@banno/jha-wc/src/cards/jha-card/jha-card.js";
import "@banno/jha-wc/src/content/jha-content/jha-content.js";
import "@banno/jha-wc/src/dropdowns/jha-dropdown/jha-dropdown.js";
import "@banno/jha-wc/src/dropdowns/jha-dropdown-menu/jha-dropdown-menu.js";
import "@banno/jha-wc/src/dropdowns/jha-dropdown-menu-item/jha-dropdown-menu-item.js";
import "@banno/jha-wc/src/dropdowns/jha-dropdown-toggle/jha-dropdown-toggle.js";
import "@banno/jha-wc/src/forms/jha-form-search-input/jha-form-search-input.js";
import "@banno/jha-wc/src/forms/jha-form-select/jha-form-select.js";
import "@banno/jha-wc/src/jha-nav/jha-nav.js";
import "@banno/jha-wc/src/jha-flex-wrapper/jha-flex-wrapper.js";
import "@banno/jha-wc/src/jha-message/jha-message.js";
import "@banno/jha-wc/src/lists/jha-list/jha-list.js";
import "@banno/jha-wc/src/lists/jha-list-item/jha-list-item.js";
import "@banno/jha-wc/src/jha-tab/jha-tab.js";
import "@banno/jha-wc/src/jha-tab-item/jha-tab-item.js";
import "@banno/jha-wc/src/sliders/jha-slider/jha-slider.js";
import "@banno/jha-wc/src/sliders/jha-slider-content/jha-slider-content.js";
import "@banno/jha-wc/src/sliders/jha-slider-pane/jha-slider-pane.js";
import "@banno/jha-wc/src/collapsible-content/jha-collapsible-group/jha-collapsible-group.js";
import "@banno/jha-wc/src/collapsible-content/jha-collapsible-section/jha-collapsible-section.js";
import "@banno/jha-wc/src/forms/jha-form-switch/jha-form-switch.js";
import "@banno/jha-wc/src/forms/jha-form-checkbox/jha-form-checkbox.js";
import "@banno/jha-wc/src/forms/jha-form-radio-button/jha-form-radio-button.js";
import "@banno/jha-wc/src/forms/jha-form-zip-input/jha-form-zip-input.js";
import "@banno/jha-wc/src/forms/jha-form-phone-number/jha-form-phone-number.js";
import "@banno/jha-wc/src/forms/jha-form-input-base/jha-form-input.js";
import "@banno/jha-wc/src/forms/jha-form-row/jha-form-row.js";
import "@banno/jha-wc/src/forms/jha-form-select-country/jha-form-select-country.js";
import "@banno/jha-wc/src/forms/jha-form-state-select/jha-form-state-select.js";
import "@banno/jha-wc/src/forms/jha-form-text-input/jha-form-text-input.js";
import "@banno/jha-wc/src/forms/jha-form-date-input-icon/jha-form-date-input-icon.js";
import "@banno/jha-wc/src/forms/jha-form-currency-input/jha-form-currency-input.js";
import "@banno/jha-wc/src/forms/jha-form-cc-input/jha-form-cc-input.js";
import "@banno/jha-wc/src/forms/jha-form-cvv-input/jha-form-cvv-input.js";
import "@banno/jha-wc/src/buttons/jha-button/jha-button.js";
import "@banno/jha-wc/src/buttons/jha-link-button/jha-link-button.js";
import "@banno/jha-wc/src/icons/jha-icon-id-card.js";
import "@banno/jha-wc/src/icons/jha-icon-monitor.js";
import "@banno/jha-wc/src/forms/jha-form-textarea-input/jha-form-textarea-input.js";
import "@banno/jha-wc/src/icons/jha-icon-search.js";
import "@banno/jha-wc/src/icons/jha-icon-location.js";
import "@banno/jha-wc/src/icons/jha-icon-document.js";
import "@banno/jha-wc/src/icons/jha-icon-download.js";
import "@banno/jha-wc/src/forms/jha-form-ssn/jha-form-ssn.js";
import "@banno/jha-wc/src/jha-filter-menu/jha-filter-menu.js";
import "@banno/jha-wc/src/jha-infinite-scroll/jha-infinite-scroll.js";
import "@banno/jha-wc/src/forms/jha-form-file-input/jha-form-file-input.js";

export function HelloBanno() {
  console.log("importing dependencies ui elements");
}
