import type IEmpWorkspaceMetadataStore from "@/branchmanager/stores/IEmpWorkspaceMetadataStore";
import { defineStore } from "pinia";
//Note: if we want to add any new property then we will add that
// in interface too but making that optional
export const EmpWorkspaceMetadataStore = defineStore({
  id: "EmpWorkspaceMetadataStore",
  actions: {
    removeErrors() {
      this.notification.messages = this.notification.messages
          .filter((notification) => notification.type !== "ERROR")
    },
    removeNotifications() {
      this.notification.messages =[];
    }
  },
  state: (): IEmpWorkspaceMetadataStore => ({
    languageConstants: {},
    error: {
      errorMessage: "",
      eodError: ""
    },
    themes: {
      themes: []
    },
    plaidlinktoken: "",
    receivedredirecturi: "",
    messages: {},
    serviceUrls: {},
    onErrorRedirectTo: "",
    coreCredentials: {
      coreLogin: null,
      corePassword: null
    },
    isExistingSignature: false,
    signatureFormTitle: "",
    signatureBase64: "",
    forceInterceptor: false,
    ignoreInterceptor: false,
    toast: {
      showToast: false,
      message: "",
      icon: "",
      type: ""
    },
    notification: {
      messages: []
    },
    dialogOptions: [],
    // we will make it optional in interface
    wizardProps: {
      continue: undefined,
      continueDisabled: false,
      back: undefined,
      continueReplacedWithSecondary: false,
      alternateBackEnabled: false,
      alternateBackText: "",
      alternateBackFunc: undefined,
      backDisabled: false,
      disabled: false,
      secondaryAction: undefined,
      secondaryActionText: "",
      secondaryActionActive: undefined,
      secondaryActionClass: "",
      continueDisabledSecondary: false,
      loading: false,
      loaderMessages: undefined,
      hideInfoBlock: false,
      hideNavigation: false,
      bltUiText: "",
      hideBackButton: false,
      hideContinueButton: false,
      showDeterminateProgressBar: false,
      searchIcon: false,
      innerScreenButtonEnabled: false,
      innerScreenBtnFunc: undefined,
      contentCardClass: "",
      dividerLine: false,
      hideTitle: false,
      contentMarginClass: "",
      hideErrorNotification: false,
      description: ""
    },
    disclosureDictionary: {
      data: {}
    },
    baseUrl: "",
    funding: {
      billingAddressList: [],
      selectedBillingAddress: {}
    }
  })
});
