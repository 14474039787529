import type { IPlaidStore } from "@/common/services/Vendor/Plaid/IPlaidStore";
import { defineStore } from "pinia";
//Note: if we want to add any new property then we will add that 
// in interface too but making that optional 
export const EmpPlaidStore = defineStore({
  id: "EmpPlaidStore",
  state: (): IPlaidStore => ({
    plaidLinkToken: "",
    plaidWorkspaceUuid: ""
  })
});