//todo(mikol): this is really to get something working now, and then we can rethink the implementation, this is
//by no means a perfect solution...
import { Service } from "@/common/dependency.configs";
import { injectable } from "inversify";

@Service
@injectable()
export default class FormValidationService {

  validate(form: HTMLFormElement): Promise<void> {
    form.dispatchEvent(new Event('submit'))
    if(form.reportValidity()) {
      return Promise.resolve()
    } else {
      return Promise.reject()
    }
  }

  validateAll(forms: HTMLFormElement[]) {

  }

}