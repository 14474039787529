export class ProductSelectionConstant {
  static readonly COMPLETE: string = "COMPLETE";
  static readonly REMOVE_DIALOG_TITLE: string = "Warning";
  static readonly REMOVE_DIALOG_CONTENT: string =
    "Removing this account will unlink all applicants." + " Are you sure you would like to continue?";
  static readonly REMOVE_DIALOG_CONFIRM: string = "Accept";
  static readonly REMOVE_DIALOG_CANCEL: string = "Cancel";
  static readonly ACCOUNT_CATEGORY: string = "Account Category";
  static readonly ALL_VALUE: string = "all";
  static readonly All_LABEL: string = "All";
  static readonly EMPTY_MESSAGE: string = "There are no accounts that match your search parameters";
  static readonly ACCOUNT_INTENTION: string = "Account Intention";
  static readonly ADD_ANOTHER_ACCOUNT: string = "Add another account";
  static readonly CONFIRM_DESCRIPTION: string = "Confirm the account(s) you've chosen to open:";
  static readonly SEARCH_PLACEHOLDER: string = "Search available accounts";
  static readonly STANDARD: string = "Standard";
  static readonly JHA_FORM_SEARCH_INPUT: string = "jha-form-search-input";
  static readonly INPUT: string = "input";
}
