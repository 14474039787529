import { inject, injectable } from "inversify";
import type IWorkspaceEntityInfoFactory from "./IWorkspaceEntityInfoFactory";
import { isEmpty } from "lodash";
import { ApplicantBusinessFactory } from "@/common/services/services.module";

@injectable()
class WorkspaceBusinessInfoFactory implements IWorkspaceEntityInfoFactory {
    constructor(
        @inject(ApplicantBusinessFactory) private applicantBusinessFactory: ApplicantBusinessFactory) {
    }
    getEntityInfo(workspaceUUID: string, entityId: number): Promise<any> {
        return this.applicantBusinessFactory.get(workspaceUUID, entityId);
    }
    getWindowTitle(info: any): string {
        return !isEmpty(info) && info.businessName ? info.businessName : "New business";
    }

}
export default WorkspaceBusinessInfoFactory;