import { inject, injectable } from "inversify";
import type IWorkspaceEntityInfoFactory from "./IWorkspaceEntityInfoFactory";
import { isEmpty } from "lodash";
import { ApplicantPersonFactory } from "@/common/services/services.module";

@injectable()
class WorkspacePersonInfoFactory implements IWorkspaceEntityInfoFactory {
    constructor(
        @inject(ApplicantPersonFactory) private applicantPersonFactory: ApplicantPersonFactory) {
    }
    getEntityInfo(workspaceUUID: string, entityId: number): Promise<any> {
        return this.applicantPersonFactory.get(workspaceUUID, entityId);
    }
    getWindowTitle(info: any): string {
        return !isEmpty(info) && info.firstName
            ? `${info.firstName} ${info.lastName}`
            : "New person";
    }

}
export default WorkspacePersonInfoFactory;