import { defineStore } from "pinia";
import { WorkflowConstant } from "@/common/constant/WorkflowConstant";
import type IEmpWorkspaceStore from "./IEmpWorkspaceStore";
export const EmpWorkspaceStore = defineStore({
  id: "EmpWorkspaceStore",
  state: (): IEmpWorkspaceStore => ({
    workspaceUUID: "",
    trackerUUID: "",
    activeApplicantId: 0,
    applicant: null,
    applicantsCount: 0,
    workflow: {
      workflowUUID: "",
      currentState: null,
      previousState: null,
      direction: WorkflowConstant.NEXT,
      transitioning: false,
    },
    transitionInfo: {
      currentNumber: 0,
      remaining: 0,
      percentage: 0,
      preloading: false
    },
    loading: false,
    enrollment: {
      enrollmentId: 0,
      workspaceUUID: ""
    },
    account: {
      accountId: 0
    },
    appCode: "boltsbranch",
    workspace: {
      workspaceUUID: "",
      workspaceType: "",
      statusCode: "",
      login: "",
      isInvite: false,
      createdDateIso: "",
      enrollments: [],
      applicants: [],
      notes: [],
      activities: []
    }
  })
});
