export class CipConstant {
  static readonly REVIEW: string = "Review";
  static readonly RUN: string = "Run";
  static readonly RE_RUN: string = "Re-run";
  static readonly REVIEW_TEXT_EMIT: string = "review";
  static readonly RUN_TEXT_EMIT: string = "run";
  static readonly REMOVE_CONFIRM_EMIT: string = "removeConfirmEmit";
  static readonly REMOVE_CANCEL_EMIT: string = "removeCancelEmit";
  static readonly RE_RUN_TEXT_EMIT: string = "reRun";
  static readonly REMOVE_APPLICANT_EMIT: string = "removeApplicantEmit";
  static readonly AVAILABLE: string = "AVAILABLE";
  static readonly INPROGRESS: string = "INPROGRESS";
  static readonly COMPLETED: string = "COMPLETED";
  static readonly COMPLETE_TEXT: string = "Complete";
  static readonly COMPLETE: string = "COMPLETE";
  static readonly INCOMPLETE_TEXT: string = "Incomplete";
  static readonly INCOMPLETE: string = "INCOMPLETE";
  static readonly CHALLENGE: string = "CHALLENGE";
  static readonly PROCESSED: string = "PROCESSED";
  static readonly FAILED: string = "FAILED";
  static readonly SERVICE_ITEM_CLICKED: string = "serviceItemClicked";
  static readonly AUTH: string = "AUTH";
  static readonly RISK: string = "RISK";
  static readonly REDFLAG: string = "REDFLAG";
  static readonly REJECT: string = "REJECT";
  static readonly ACCEPT: string = "ACCEPT";
  static readonly FAIL: string = "FAIL";
  static readonly FAILED_CIP_INDICATOR: string = "(Failed CIP)";
  static readonly PANES: { ALL: string; IDA: string; RISK: string; REPORT: string } = {
    ALL: "allservice",
    IDA: "idaServicePane",
    RISK: "riskServicePane",
    REPORT: "reportServicePane"
  };
  static readonly OVERRIDE: string = "Override";
  static readonly ICON = {
    MONITOR: "MONITOR",
    CHECKMARK: "CHECKMARK",
    WARNING: "WARNING",
    CIRCLE_WARNING: "CIRCLE_WARNING",
    CIRCLE_CLOCK: "CIRCLE_CLOCK"
  };
  static readonly EDIT_QUESTION_CONFIRM_BUTTON_TEXT: string = "Yes, proceed";
  static readonly FAILED_CIP_REMOVED_INDICATOR: string = "(Failed CIP, Removed)";
  static readonly REMOVED_INDICATOR: string = "(Removed)";
  static readonly EDIT_QUESTION_CANCEL_BUTTON_TEXT: string = "Cancel";
  static readonly EMAIL_DIALOG_TITLE: string = "Email adverse action";
  static readonly EMAIL_DIALOG_SUBMIT_LABEL: string = "Send";
  static readonly EMAIL_DIALOG_DESCRIPTION: string = "Select the email address to send the adverse action to.";
  static readonly EMAIL_BUTTON_LABEL: string = "Email";
  static readonly PRINT_BUTTON_LABEL: string = "Print";
  static readonly QUESTION_SEPERATER: string = "/";
  static readonly VIEW_ADVERSE_ACTION: string = "View adverse action";
  static readonly SUBMIT_ADVERSE_ACTION: string = "Submit adverse action";
  static readonly REMOVE_APPLICANT: string = "Remove applicant";
  static readonly CANCEL: "Cancel";
  static readonly SUBMIT: "Submit";
}
