import { defineStore } from "pinia";
import type IWorkflowStore from "./IWorkflowStore";
import type { IError } from "@/common/models/IError";

export const EmpWorkflowStore = defineStore({
    id: "EmpWorkflowStore",
    state: (): IWorkflowStore => ({
        headerActions: [] as { title: string; titleKey: string; action: any }[],
        windowTitle: "",
        errorLog: [] as IError[]
    })
});
