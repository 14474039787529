import type IUiFieldsFactory from "@/common/services/Fields/IUiFieldsFactory";
import { UIFieldsFactory } from "@/common/services/services.module";
import { injectable, inject } from "inversify";

@injectable()
class EmpUiFielsFactory implements IUiFieldsFactory {
  constructor(@inject(UIFieldsFactory) private uiFieldsFactory: UIFieldsFactory) {}

  get(messageCategoryId: number) {
    return this.uiFieldsFactory.get(messageCategoryId);
  }
  getAll() {
    return this.uiFieldsFactory.getAll();
  }
}

export default EmpUiFielsFactory;
