<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "bltNavContainer"
});
</script>
<template>
  <div class="blt-nav-container">
    <div>
      <slot name="header"></slot>
    </div>
    <jha-container class="j-container">
      <div class="content">
        <div class="workflow-container">
          <slot name="left"></slot>
          <div class="gap"></div>
          <slot name="right"></slot>
        </div>
      </div>
    </jha-container>
  </div>
</template>