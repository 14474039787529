export class EntryConstant {
  static readonly NEW_PERSON_WORKSPACE: string = "New person workspace";
  static readonly NEW_BUSINESS_WORKSPACE: string = "New business workspace";
  static readonly FA_USER: string = "fa-user";
  static readonly SUBHEADING_PERSON: string = "Add people to a deposit account on new enrollment";
  static readonly SUBHEADING_BUSINESS: string = "Add people and businesses to a deposit account on new enrollment";
  static readonly FA_SUITCASE: string = "fa-suitcase";
  static readonly NEW_PERSON_WORKSPACE_ID: string = "new_person_workspace";
  static readonly NEW_BUSINESS_WORKSPACE_ID: string = "new_business_workspace";
  static readonly TITLE: string = "JHA OpenAnywhere™ | Employee Channel";
  static readonly EMPLOYEE_BRANCH_LOCATION: string = "EMPLOYEE_BRANCH_LOCATION";
  static readonly LOCAL_STORAGE_USER_NAME: string = "u";
  static readonly LOCAL_STORAGE_PWD: string = "p";
  static readonly CHECK_VALIDATION_TYPE_ALL: string = "ALL";
  static readonly PRODUCT_SELECTION: string = "PRODUCT_SELECTION";
}
