import type IColourSchemeUtils from "@/common/services/IColorSchemeUtil";
import { injectable } from "inversify";

@injectable()
class ColorSchemeUtil implements IColourSchemeUtils {
  menuImages: any;
  institutionConfig: any;
  constructor() {
    this.institutionConfig = (window as any).institutionConfig;
    this.menuImages = this.institutionConfig.images["menu-logos"].default;

    this.onColorSchemeChange();

    window.matchMedia("(prefers-color-scheme: dark)")
        .addEventListener(
            "change",
            () => {this.onColorSchemeChange()}
        );
  }

  isDarkMode() {
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  }

  onColorSchemeChange(isBranchManager = false) {
    const logoPath = this.isDarkMode() ? this.menuImages.dark : this.menuImages.light;
    return new URL(logoPath, window.location.origin).href;
  }
}

export default ColorSchemeUtil;
