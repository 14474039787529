<template>
  <div class="blt-account-header-wrapper">
    <div class="d-flex">
      <div class="mailing-header-icons">
        <jha-icon-check
          class="checking-icon"
          primary=""
          v-if="account.accountGroup === PRODUCT_TYPES.CHECKING"
        ></jha-icon-check>

        <jha-icon-piggy-bank
          class="checking-icon"
          primary=""
          v-if="account.accountGroup === PRODUCT_TYPES.SAVINGS"
        ></jha-icon-piggy-bank>

        <jha-icon-date
          class="checking-icon"
          primary=""
          v-if="account.accountGroup === PRODUCT_TYPES.CD"
        ></jha-icon-date>
      </div>
      <div class="mailing-header-section">
        <div class="mailing-header-title">
          {{ account.accountMinorDescription }}
        </div>
        <div class="mailing-header-type" v-if="account.description">
          {{ account.description }}
        </div>
      </div>
    </div>
    <slot name="right-section">
      <div class="status" :class="{ complete: isAccountComplete }" v-if="status">
        <jha-icon-checkmark class="jha-icon-checkmark-success" v-if="isAccountComplete"> </jha-icon-checkmark>
        <span :class="{ 'complete-txt': isAccountComplete }">{{ status }}</span>
      </div>
    </slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { DisplayConstant } from "@/common/constant/DisplayConstant";
import PRODUCT_TYPES from "@/common/enums/productTypes";
import type { IRoleAccount } from "@/common/models/IRoles";

export default defineComponent({
  name: "bltAccountHeader",
  props: {
    account: {
      type: Object as PropType<IRoleAccount>,
      required: true
    },
    status: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      PRODUCT_TYPES
    };
  },
  computed: {
    isAccountComplete(): boolean {
      return this.status === DisplayConstant.COMPLETE_MSG;
    }
  }
});
</script>

<style scoped>
.blt-account-header-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.mailing-header-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--jha-text-dark);
}

.mailing-header-type {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--body-text-secondary-color);
}

.jha-icon-checkmark-success {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  padding-right: 4px;
  fill: var(--jha-color-success);
}

.checking-icon {
  width: 20px;
  height: 20px;
  padding-right: var(--jh-space-200);
  padding-left: var(--jh-space-100);
  fill: var(--jha-text-dark);
  vertical-align: middle;
}

.status {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: var(--body-text-secondary-color);
  padding-right: var(--jh-space-300);
  padding-top: var(--jh-space-200);
  padding-bottom: var(--jh-space-200);
}

.complete-txt {
  color: var(--jha-color-success);
}
@media (max-width: 468px) {
  .mailing-header-title {
    width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;

  }
  .status {
    width: 84px;
    text-align: right;
  }

}
@media (max-width: 375px) {
  .mailing-header-title {
    width: 130px;
  }
}
@media (max-width: 320px) {
  .mailing-header-title {
    width: 100px;
  }
}
</style>
