import {inject, injectable} from 'inversify'
import ConstantFactory from '../../utils/ConstantFactory'
import PreloadService from '@/common/services/PreloadService/PreloadService'
import {Service} from '@/common/dependency.configs'

@Service
@injectable()
class IDScanIDVCService {
  constructor(
    @inject(ConstantFactory) private constantFactory: ConstantFactory,
    @inject(PreloadService) private preloadService: PreloadService
  ) {}

  private IDSCAN_CACHE_NAME = 'DVS' as const
  private IDSCAN_CACHE_FILES = [
    'Blur-chunk.js',
    'Bubble-chunk.js',
    'ClassLocal-chunk.js',
    'Face-chunk.js',
    'GeneralTypeDetectionYolo-chunk.js',
    'MRZ-chunk.js',
    'PDF-chunk.js',
    'ZXing-chunk.js',
    'angle03032023.onnx.gzip',
    'blurWorker.js.gzip',
    'bubbleWorker.js.gzip',
    'classLocal03032023.onnx.gzip',
    'faceDetection03032023.onnx.gzip',
    'faceLandmark03032023.onnx.gzip',
    'generalTypeDetectionYolo.onnx.gzip',
    'mrz03032023.onnx.gzip',
    'mrzWorker.js.gzip',
    'ort-wasm-simd.wasm.gzip',
    'ort-wasm.wasm.gzip',
    'pdfWorker.js.gzip'
  ] as const;

  async preload() {
    const uris: URL[] = this.IDSCAN_CACHE_FILES.map((it) => new URL(`${window.location.origin}/networks/${it}`))
    await this.preloadService.load(this.IDSCAN_CACHE_NAME, uris)
  }

  ocrTypesToIdvcTypes(imageTypes: Array<IOcrIdTypes>): Array<IDVCConfigDocument> {
    return imageTypes.map((imagetype) => ({
      type: this.getDvsIdType(imagetype),
      steps: this.imageTypeToSteps(imagetype)
    }))
  }

  imageTypeToSteps(idType: IOcrIdTypes): Array<IDVCConfigDocumentSteps> {
    return idType.imageTypes.map((imageType: IOcrIdTypeImage) => {
      switch (imageType.imageType) {
        case 'DRIVER_LICENSE_FRONT':
        case 'PERMANENT_RESIDENT_CARD_FRONT':
        case 'STATE_ID_FRONT':
          return {
            type: 'front',
            name: imageType.description,
            mode: {
              uploader: true,
              video: true
            }
          }
        case 'DRIVER_LICENSE_BACK':
        case 'PERMANENT_RESIDENT_CARD_BACK':
        case 'STATE_ID_BACK':
          return {
            type: 'pdf',
            name: imageType.description,
            mode: {
              uploader: true,
              video: true
            }
          }
        case 'PASSPORT':
          return {
            type: 'mrz',
            name: imageType.description,
            mode: {
              uploader: true,
              video: true
            }
          }
        case 'FACE_IMAGE':
          return {
            type: 'face',
            name: 'Face Image',
            mode: {
              uploader: !this.constantFactory.getBool('IDSCAN_DVS_DISABLE_UPLOAD'),
              video: true
            }
          }
      }
    })
  }

  getDvsIdType(boltsIdType: IOcrIdTypes) {
    switch (boltsIdType.identificationType) {
      case 'DRIVER_LICENSE':
        return 'ID'
      case 'PASSPORT':
        return 'Passport'
      case 'ALIEN_ID':
        return 'GreenCard'
      case 'STATE_ID':
        return 'ID'
      default:
        return 'ID'
    }
  }

  idTypesToTypes(idTypes: Array<IOcrIdTypes>): Array<string> {
    const returnVal = idTypes.filter((type) => type.enabled).map(this.getDvsIdType)
    return Array.from(new Set(returnVal))
  }
}

export default IDScanIDVCService
