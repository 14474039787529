<script lang="ts">
import { defineComponent } from "vue";
import { $injByInterface, $inj } from "@/common/decorators/depinject";
import { WorkspaceStatesConstant } from "@/branchmanager/app/constant/WorkspaceStatesConstant";
import type IRouterService from "@/common/services/utils/IRouterService";
import type IWorkspaceMetadataStore from "@/common/services/Workspace/IWorkspaceMetadataStore";
import type INotificationFactory from "@/common/services/INotificationFactory";
import type { IErrorMessage } from "@/common/models/IErrorMessage";
import { NotificationMessageCategory } from "@/branchmanager/app/constant/NotificationMessageCategory";
import { WorkspaceConstants } from "@/common/constant/WorkspaceConstants";
import { WorkflowConstant } from "@/common/constant/WorkflowConstant";
import { NotificationConstants } from "@/common/constant/NotificationConstants";
import { OpenLanguageKeysConstant } from "@/common/constant/OpenLanguageKeysConstant";
import { LanguageFactory } from "@/common/services/services.module";
import {ApplicantSearchStore} from "@/branchmanager/stores/ApplicantSearchStore";

export default defineComponent({
  name: "bltWorkspaceWindow",
  emits: ["close"],
  props: {
    heading: {
      type: String,
      default: ""
    },
    back: {
      type: Function,
      default: undefined
    },
    showBack: {
      type: Boolean,
      default: false
    },
    dateTime: {
      type: String,
      default: ""
    },
    statusCode: {
      type: String,
      default: ""
    },
    squareIcon: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const languageFactory = $inj(LanguageFactory);
    const routerService = $injByInterface<IRouterService>("IRouterService");
    const workspaceMetadataStore = $injByInterface<IWorkspaceMetadataStore>("IWorkspaceMetadataStore");
    const notificationFactory = $injByInterface<INotificationFactory>("INotificationFactory");
    const applicantSearchStore = ApplicantSearchStore()

    return {
      languageFactory,
      routerService,
      workspaceMetadataStore,
      notificationFactory,
      applicantSearchStore
    };
  },
  data() {
    return {
      showCloseWorkspaceDialog: false as boolean,
      workspaceClosedNotification: "" as string,
      WorkspaceConstants
    };
  },
  async created() {
    await this.getClosedNotification();
  },
  computed: {
    workspaceStatus(): string {
      return this.statusCode?.charAt(0).toUpperCase() + this.statusCode?.slice(1).toLowerCase();
    },
    workspaceStatusClass(): string {
      return `tag-${this.workspaceStatus.toLowerCase()}-wrapper`;
    },
    showSecondaryTitleSection(): string {
      return this.statusCode && this.dateTime;
    },
    systemMessages(): IErrorMessage[] {
      return this.workspaceMetadataStore.notification.messages.filter(
        (message) => message.messageCategory === NotificationMessageCategory.SYSTEM
      );
    },
    getWorkspaceClosedNotification(): IErrorMessage[] {
      return [{ message: this.workspaceClosedNotification, type: NotificationConstants.WARN }];
    }
  },
  watch: {
    statusCode: {
      immediate: true,
      async handler() {
        if (!this.workspaceClosedNotification) {
          await this.getClosedNotification();
        }
        if (this.workspaceStatus.toUpperCase() === WorkflowConstant.CLOSED) {
          const workspaceClosedMessage = {
            ...this.getWorkspaceClosedNotification[0],
            messageCategory: NotificationMessageCategory.SYSTEM
          };
          if (this.workspaceMetadataStore.notification.messages.indexOf(workspaceClosedMessage) === -1)
            this.workspaceMetadataStore.notification.messages.push(workspaceClosedMessage);
        } else {
          this.notificationFactory.clearSystemMessages();
        }
      }
    }
  },
  methods: {
    close(): void {
      const args = { handled: false };
      this.$emit("close", args);
      if (!args.handled) {
        this.routerService.push({
          name: WorkspaceStatesConstant.DESKTOP.STATE,
          params: {},
          replace: true
        });
      }
      this.showCloseWorkspaceDialog = false;
      this.notificationFactory.clearMessages();
    },
    goBack(): void {
      if (this.back) this.back();
      else this.routerService.go(-1);
    },
    async getClosedNotification(): Promise<void> {
      this.workspaceClosedNotification = await this.languageFactory.get(
        OpenLanguageKeysConstant.WORKSPACE_CLOSED_BANNER
      );
    }
  }
});
</script>
<template>
  <div id="page-header">
    <div class="left">
      <div class="d-flex">
        <div class="m-tb-auto" v-if="showBack">
          <jha-icon-arrow-back
            class="previous-page"
            tabindex="0"
            role="button"
            @keydown.enter="goBack"
            @click="goBack"
          ></jha-icon-arrow-back>
        </div>
        <div class="icon-round m-tb-auto" :class="{ 'with-square': squareIcon }" v-else>
          <slot name="iconsection"></slot>
        </div>
        <div class="details">
          <div class="header-primary truncate-primary-header">{{ heading }}</div>
          <div class="header-secondary d-flex ptb-4" v-if="dateTime || statusCode">
            <div class="truncate-secondary-header" v-if="dateTime">Created: {{ dateTime }}</div>
            <div v-if="statusCode" :class="workspaceStatusClass">{{ workspaceStatus }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="right d-flex">
      <div class="header-right d-flex">
        <slot name="ellipses"></slot>
        <jha-icon-close
          tabindex="0"
          role="button"
          @keydown.enter="showCloseWorkspaceDialog = true"
          @click="showCloseWorkspaceDialog = true"
        ></jha-icon-close>
      </div>
    </div>
  </div>

  <bltModalDialog
    :showDialog="showCloseWorkspaceDialog"
    type="small-dialog"
    :clickOutsideToClose="true"
    :cancel-label="WorkspaceConstants.CANCEL_DIALOG"
    :show-cancel="true"
    @cancel="showCloseWorkspaceDialog = false"
    :confirm-label="WorkspaceConstants.CONFIRM_DIALOG"
    :show-confirm="true"
    @confirm="close"
    :title="WorkspaceConstants.CLOSE_WORKSPACE"
    :messageBody="WorkspaceConstants.CLOSE_WORKSPACE_BODY"
    stateClass="content-width"
  >
  </bltModalDialog>

  <div class="container">
    <div class="emp-notification-container" v-if="systemMessages.length" id="empNotificationForScroll">
      <blt-notification-emp :messages="systemMessages"></blt-notification-emp>
    </div>
    <slot name="notifications"></slot>
    <slot></slot>
  </div>
</template>

<style scoped>
.header-primary {
  font-size: 20px;
  color: var(--white-color);
  font-weight: 400;
  line-height: 28px;
}

.header-secondary {
  font-size: 12px;
  color: var(--white-color);
  font-weight: 400;
  line-height: 16px;
}

.tag-open-wrapper {
  background-color: var(--primary-color);
  border-radius: 4px;
  padding: 0 4px;
  width: 37px;
  height: 16px;
  font-size: 12px;
  font-weight: 500;
  margin-left: 8px;
  line-height: 16px;
}
.tag-closed-wrapper {
  color: var(--body-text-secondary-color);
  background-color: var(--container-secondary-color);
  border-radius: 4px;
  padding: 0 4px;
  height: 16px;
  font-size: 12px;
  font-weight: 500;
  margin-left: 8px;
  line-height: 16px;
}

.icon-round {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--container-secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--jh-space-200);
}

.with-square {
  border-radius: 4px;
}

.icon-pos {
  vertical-align: middle;
}

.icon-round .user-icon {
  width: 32px;
  height: 32px;
  color: var(--body-text-secondary-color);
}

.icon-view {
  fill: var(--white-color);
  width: 24px;
  height: 24px;
  cursor: pointer;
}

#page-header {
  justify-content: left;
}

.previous-page {
  padding-right: var(--jh-space-200);
  fill: var(--container-secondary-color);
  cursor: pointer;
}

.emp-notification-container:last-child {
  margin-bottom: 0px;
}

@media (max-width: 393px) {
  .truncate-primary-header {
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .truncate-secondary-header {
    width: 128px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 280px) {
  .truncate-primary-header {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .truncate-secondary-header {
    width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
