export default {
  INITIAL_DISCLOSURE:"INITIAL_DISCLOSURE",
  PERSON_INFO:"PERSON_INFO",
  OCR:"OCR",
  PHONE:"PHONE",
  EMAIL:"EMAIL",
  IDENTIFICATION:"IDENTIFICATION",
  ADDRESS:"ADDRESS",
  ALL:"ALL",
  DEMOGRAPHICS:"DEMOGRAPHICS",
  EMPLOYMENT:"EMPLOYMENT",
  PREQUALIFICATION:"PREQUALIFICATION",
  BUSINESS_INFO:"BUSINESS_INFO",
  DOCUMENT:"DOCUMENT",
  ACCOUNT_APPLICANT_DISCLOSURE: "ACCOUNT_APPLICANT_DISCLOSURE",
  INCOME: "INCOME", // todo(mikol): this does not exist on the API yet...
  EXPENSES: "EXPENSES",
} as const;