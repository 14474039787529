import type ICardContentHeightUtil from "@/common/services/utils/ICardContentHeightUtil";
import { injectable } from "inversify";

@injectable()
class EmpCardContentHeightUtil implements ICardContentHeightUtil {
  /**Calculate expected maximum height of the card content so that iframe directive
   * either takes this allowed max height or stay with it's own document content height
   */
  calculateMaximumAllowedShellContentHeight(): number {
    /**SINCE UI IS DYNAMICALLY GENERATED WITH DATA BINDINGS FROM API, ITS VERY UNSTABLE, SO FIX CONST HEIGHT CONSIDERED */
    const lineHeights = 2; //assume some divider line heights
    const MINHEIGHTALLOWED = 180;
    /**END OF FIX CONST HEIGHT CONSIDERATION */
    try {
      const outerShellContent = window.document.getElementsByClassName("container")[0];
      let outerShellContentHeight = 0;
      //in case browser scrollbar comes up and shell height doesn't reduce.
      const adjustmentOfWindowScrollHeight =
        window.document.documentElement.scrollHeight - window.document.documentElement.clientHeight;

      if (outerShellContent) {
        outerShellContentHeight = outerShellContent.clientHeight - adjustmentOfWindowScrollHeight;
      }
      let greenTopMarginHeight = 0;
      let greenBottomMarginHeight = 0;
      let cardBodyPaddingTop = 0;
      let pageHeightHeight = 0;
      const contentpaddingwrapper = window.document.getElementById("page-header") as HTMLElement;
      if (contentpaddingwrapper) {
        pageHeightHeight = contentpaddingwrapper.clientHeight;
        const contentWrapperComputedStyles = window.getComputedStyle(contentpaddingwrapper);
        greenTopMarginHeight = this.convertPaddingToNumber(contentWrapperComputedStyles["marginTop"]);
        greenBottomMarginHeight = this.convertPaddingToNumber(contentWrapperComputedStyles["marginBottom"]);
      }
      const outerContentCardHeight = outerShellContentHeight - greenTopMarginHeight - greenBottomMarginHeight;

      let contentCardHeaderHeight = 0;
      let cardFooterHeight = 0;
      let continueButtonFooterHeight = 0;
      let subHeaderHeight = 0;
      let dividerHeight = 0;
      let currentApplicantWrapperHeight = 0;
      let progressbarContainerHeight = 0;
      let contentBoxWrapperPaddingTop = 0;
      let notificationWrapperHeight = 0;

      const cardBody = window.document.getElementsByClassName("card-body");
      const contentCardHeader = window.document.getElementsByClassName("jh-card-header");
      const cardFooter = window.document.getElementsByClassName("card-footer");
      const progressbarContainer = window.document.getElementsByClassName("progressbar-container");
      const cardFooterContent = window.document.getElementsByClassName("footer-content");
      const notificationWrapper = window.document.getElementsByClassName("notification-wrapper");
      const subHeaderElements = window.document.getElementsByClassName("subheader");
      const currentApplicantWrapper = window.document.getElementsByClassName("blt-current-applicant-wrapper");
      const contentBoxWrapper = window.document.getElementById("content-box") as HTMLElement;
      const dividerLineWrapper = window.document.getElementById("dividerLine") as HTMLElement;
      if (cardBody.length) {
        const cardBodyComputedStyles = window.getComputedStyle(cardBody[0]);
        cardBodyPaddingTop = this.convertPaddingToNumber(cardBodyComputedStyles["paddingTop"]);
      }
      if (contentCardHeader.length) {
        contentCardHeaderHeight = contentCardHeader[0].clientHeight;
      }
      if (cardFooter.length) {
        cardFooterHeight = cardFooter[0].clientHeight;
      }
      if (progressbarContainer.length) {
        progressbarContainerHeight = progressbarContainer[0].clientHeight;
      }
      if (cardFooterContent.length) {
        continueButtonFooterHeight = cardFooterContent[0].clientHeight;
      }
      if (subHeaderElements.length) {
        subHeaderHeight = subHeaderElements[0].clientHeight;
      }
      if (notificationWrapper.length) {
        notificationWrapperHeight = notificationWrapper[0].clientHeight;
      }
      if (dividerLineWrapper) {
        const dividerLineWrapperComputedStyles = window.getComputedStyle(dividerLineWrapper);
        const dividerLineMarginBottom = this.convertPaddingToNumber(dividerLineWrapperComputedStyles["marginBottom"]);
        dividerHeight = dividerLineMarginBottom + dividerLineWrapper.offsetHeight;
      }
      if (currentApplicantWrapper.length) {
        currentApplicantWrapperHeight = currentApplicantWrapper[0].clientHeight;
      }

      if (contentBoxWrapper) {
        const contentBoxComputedStyles = window.getComputedStyle(contentBoxWrapper);
        contentBoxWrapperPaddingTop = this.convertPaddingToNumber(contentBoxComputedStyles["paddingTop"]);
      }
      const progressBarHeight = progressbarContainerHeight;
      const progressBarAndSubHeaderHeight =
        cardBodyPaddingTop +
        progressBarHeight +
        subHeaderHeight +
        currentApplicantWrapperHeight +
        lineHeights +
        notificationWrapperHeight;

      const headerFooterFixedHeights = contentCardHeaderHeight + continueButtonFooterHeight + cardFooterHeight;

      const maximumAllowedContentHeight =
        outerContentCardHeight -
        headerFooterFixedHeights -
        progressBarAndSubHeaderHeight -
        pageHeightHeight +
        contentBoxWrapperPaddingTop;

      return maximumAllowedContentHeight > MINHEIGHTALLOWED ? maximumAllowedContentHeight : MINHEIGHTALLOWED;
    } catch (err) {
      return 0;
    }
  }

  convertPaddingToNumber(padding: string): number {
    return parseFloat(padding);
  }

  /** Its get the target element scroll height and  maximum content  height and set the minimum height to the target element.
   */
  setElementHeightToMinimumOfPossibleHeight(targetElementId: string, maximumAllowedShellContentHeight: number): void {
    const timeIntervel = setInterval(() => {
      const getTargetElementID = window.document.getElementById(targetElementId) as any;
      if (undefined != getTargetElementID) {
        const elementContentHeight = getTargetElementID.scrollHeight;
        const minimumHeight = Math.min(maximumAllowedShellContentHeight, elementContentHeight);
        getTargetElementID.style.height = `${minimumHeight}px`;
        clearInterval(timeIntervel);
      }
    }, 10);
  }
}

export default EmpCardContentHeightUtil;
