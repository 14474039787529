import DEPENDENCYTYPES from "@/common/dependency.types";
import { inject, injectable } from "inversify";
import type IWorkspaceMetadataStore from "@/common/services/Workspace/IWorkspaceMetadataStore";
import { NotificationConstants } from "@/common/constant/NotificationConstants";
import type INotificationFactory from "@/common/services/INotificationFactory";
import { NotificationMessageCategory } from "@/branchmanager/app/constant/NotificationMessageCategory";
import { BltDateFilter, ScrollToService } from "@/common/services/services.module";
import type IWorkflowStore from "@/branchmanager/stores/IWorkflowStore";
import type IRouterService from "@/common/services/utils/IRouterService";
import ERROR from "@/common/enums/errorEnum";
import type IWfState from "@/common/models/WfState";
import type { Detail } from "@/common/models/IStateValidationResponse";
import type RestResponse from "@/common/data/RestResponse";

@injectable()
class EmpNotificationFactory implements INotificationFactory {
  constructor(
    @inject(DEPENDENCYTYPES.IWorkspaceMetadataStore) private workspaceMetadataStore: IWorkspaceMetadataStore,
    @inject(DEPENDENCYTYPES.IWorkflowStore) private workflowStore: IWorkflowStore,
    @inject(BltDateFilter) private bltDateFilter: BltDateFilter,
    @inject(DEPENDENCYTYPES.IRouterService) private routerService: IRouterService,
    @inject(ScrollToService) private scrollToService: ScrollToService
  ) {}

  showData = (data: {
    infos: { message: string }[];
    warnings: { message: string }[];
    errors: { message: string; code: string }[];
  }): void => {
    if (data !== undefined) {
      if (Array.isArray(data.infos)) {
        data.infos.forEach((info: { message: string }) => {
          this.info(info.message);
        });
      }
      if (Array.isArray(data.warnings)) {
        data.warnings.forEach((warn: { message: string }) => {
          this.warn(warn.message);
        });
      }
      if (Array.isArray(data.errors)) {
        data.errors.forEach((er: { message: string; code: string }) => {
          if (er.code != ERROR.WORKSPACE_IS_CLOSED) {
            this.error(er.message);
          } else {
            this.error(er.message, NotificationMessageCategory.SYSTEM);
          }
        });
      }
    }
  };

  getMessageCategory(): string {
    const hasRoute = this.routerService.currentRoute.value.path.includes("/workflow/");
    return hasRoute ? NotificationMessageCategory.WORKFLOW : NotificationMessageCategory.SYSTEM;
  }

  clearMessages(): void {
    this.workspaceMetadataStore.notification.messages = [];
  }

  //todo(mikol): this is all a dumpster fire, we need to remove this global error concept ASAP.
  setErrorMessagesFromDetails(details: Detail[]): void {
   this.clearMessages();
   try {
     this.workspaceMetadataStore.notification.messages = details.map((detail) => {
       return {
         message: detail.message,
         type: NotificationConstants.ERROR,
         messageCategory: NotificationMessageCategory.WORKFLOW,
         isTopLevel: true //todo(mikol): nothing uses this, remove it...
       }
     })
   } catch (e) {
     console.error("Unexpected value received when trying to set error messaged from Detail[], actual value received: ", details)
     this.setInternalError("Oops, an unexpected error occurred")
   }

  }

  //todo(mikol): this is all a dumpster fire, we need to remove this global error concept ASAP.
  setErrorMessagesFromRestResponse(restResponse:RestResponse<unknown>) {
    this.workspaceMetadataStore.notification.messages = restResponse.data.errors?.map((error) => {
      return {
        message: error.message,
        type: NotificationConstants.ERROR,
        messageCategory: NotificationMessageCategory.WORKFLOW,
        isTopLevel: true //todo(mikol): nothing uses this, remove it..
      }
    }) ?? []
  }

  private setInternalError(message: string): void {
    this.workspaceMetadataStore.notification.messages = [{
      message: message,
      type: NotificationConstants.ERROR,
      messageCategory: NotificationMessageCategory.WORKFLOW,
      isTopLevel: true,
    }]
  }

  clearSystemMessages(): void {
    const workflowMessages = this.workspaceMetadataStore.notification.messages.filter(
      (message) => message.messageCategory !== NotificationMessageCategory.SYSTEM
    );
    this.workspaceMetadataStore.notification.messages = workflowMessages;
  }

  clearWorkflowMessages(): void {
    const systemMessages = this.workspaceMetadataStore.notification.messages.filter(
      (message) => message.messageCategory !== NotificationMessageCategory.WORKFLOW
    );
    this.workspaceMetadataStore.notification.messages = systemMessages;
  }

  removeErrorMessage(message: string): void {
    const messages = this.workspaceMetadataStore.notification.messages;
    if (messages.map((m) => m.message).includes(message)) {
      messages.splice(messages.findIndex((m) => m.message.includes(message)));
    }
    this.workspaceMetadataStore.notification.messages = messages;
  }

  removeMessage(message: string): void {
    const messages = this.workspaceMetadataStore.notification.messages;
    if (messages.map((m) => m.message).includes(message)) {
      messages.splice(
        messages.findIndex((m) => m.message.includes(message)),
        1
      );
    }
    this.workspaceMetadataStore.notification.messages = messages;
  }

  error(message: string, messageCategory?: string): void {
    const messages = this.workspaceMetadataStore.notification.messages;
    if (!messages.map((m) => m.message).includes(message))
      this.workspaceMetadataStore.notification.messages.push({
        message: message,
        type: NotificationConstants.ERROR,
        messageCategory: messageCategory || this.getMessageCategory()
      });

    this.workflowStore.errorLog?.unshift({
      message: message,
      type: NotificationConstants.ERROR,
      messageCategory: this.getMessageCategory(),
      date: this.bltDateFilter.bltDate(new Date())
    });
    this.scrollToErrorNotification(messageCategory || this.getMessageCategory());
  }

  warn(message: string, messageCategory?: string): void {
    const messages = this.workspaceMetadataStore.notification.messages;
    if (!messages.map((m) => m.message).includes(message))
      this.workspaceMetadataStore.notification.messages.push({
        message: message,
        type: NotificationConstants.WARN,
        messageCategory: messageCategory || this.getMessageCategory()
      });
  }

  info(message: string, messageCategory?: string): void {
    const messages = this.workspaceMetadataStore.notification.messages;
    if (!messages.map((m) => m.message).includes(message))
      this.workspaceMetadataStore.notification.messages.push({
        message: message,
        type: NotificationConstants.INFO,
        messageCategory: messageCategory || this.getMessageCategory()
      });
  }

  scrollToErrorNotification(messageCategory: string): void {
    messageCategory === NotificationMessageCategory.WORKFLOW
      ? this.scrollToService.scrollWindowToId("workflowNotificationScroll")
      : this.scrollToService.scrollWindowToId("empNotificationForScroll");
  }

  showWorkflowStateNotifications(state: IWfState): void {
    if (state.errors?.length) {
      this.removeMessage(state.errors[0]?.message);
      this.workspaceMetadataStore.notification.messages.push({
        message: state.errors[0]?.message,
        type: NotificationConstants.ERROR,
        messageCategory: NotificationMessageCategory.WORKFLOW,
        isTopLevel: true
      });
    }
    if (state.warnings?.length) {
      this.removeMessage(state.warnings[0]?.message);
      this.workspaceMetadataStore.notification.messages.push({
        message: state.warnings[0]?.message,
        type: NotificationConstants.WARN,
        messageCategory: NotificationMessageCategory.WORKFLOW,
        isTopLevel: true
      });
    }
    if (state.infos?.length) {
      this.removeMessage(state.infos[0]?.message);
      this.workspaceMetadataStore.notification.messages.push({
        message: state.infos[0]?.message,
        type: NotificationConstants.INFO,
        messageCategory: NotificationMessageCategory.WORKFLOW,
        isTopLevel: true
      });
    }
  }
}

export default EmpNotificationFactory;
