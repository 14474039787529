import DEPENDENCYTYPES from "@/common/dependency.types";
import type IHttpErrorHandler from "@/common/services/connect/IHttpErrorHandler";
import type INotificationFactory from "@/common/services/INotificationFactory";
import { inject, injectable } from "inversify";

@injectable()
class EmpHttpErrorHandler implements IHttpErrorHandler {
  constructor(@inject(DEPENDENCYTYPES.INotificationFactory) private notificationFactory: INotificationFactory) {}

  handle(errResponse: { data: { errors: []; infos: []; warnings: [] } }): void {
    this.notificationFactory.showData(errResponse.data);
  }
}

export default EmpHttpErrorHandler;
