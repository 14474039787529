import { injectable, inject } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import NotificationFactory from "@/common/services/notificationFactory";
import type IApplicantValidationFactory from "@/common/services/Applicant/IApplicantValidationFactory";
import { EntryConstant } from "@/branchmanager/app/constant/EntryConstant";

@injectable()
class EmpApplicantValidationFactory implements IApplicantValidationFactory {
  constructor(
    @inject(NotificationFactory) private notificationFactory: NotificationFactory,
    @inject(HttpHandler) private httpHandler: HttpHandler
  ) {}

  getValidation(workspaceUUID: string, applicantId: number, state: any): Promise<any> {
    return this.httpHandler
      .get(
        `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/validate?type=${state}`,
        {},
        ResponseTypes.Payload
      );
  }

  checkValidation(workspaceUUID: string, applicantId: number): Promise<any> {
    return this.getValidation(workspaceUUID, applicantId, EntryConstant.CHECK_VALIDATION_TYPE_ALL).then((response) => {
      if (Array.isArray(response.errors) && response.errors.length > 0) {
        this.notificationFactory.showData(response);
        return Promise.reject(response.errors);
      }
      return response;
    });
  }
}
export default EmpApplicantValidationFactory;
