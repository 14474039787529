import { Service } from "@/common/dependency.configs";
import { injectable } from "inversify";

type QuerySelectorAllHtmlElement<K extends keyof HTMLElementTagNameMap> = Array<HTMLElementTagNameMap[K]>
type QuerySelectorAllSvgElement<K extends keyof SVGElementTagNameMap> = Array<SVGElementTagNameMap[K]>
type QuerySelectorAllGenericElement<E extends Element = Element> = Array<E>

type QuerySelectorHtmlElement<K extends keyof HTMLElementTagNameMap> = HTMLElementTagNameMap[K] | null
type QuerySelectorSvgElement<K extends keyof SVGElementTagNameMap> = SVGElementTagNameMap[K] | null
type QuerySelectorGenericElement<E extends Element = Element> = E | null

/**
 *  This is a utility service for querying / traversing through the DOM including ShadowDOM elements
 */

@Service
@injectable()
export default class ShadowDomService {

  /**
   * This functions exactly like the native {@link Document.querySelectorAll}, but it also traverses shadowDom tree
   * structures as well. Note that this returns an Array rather than a NodeList.
   */
  querySelectorAll<T extends keyof HTMLElementTagNameMap>(selectors: T, element: Element): QuerySelectorAllHtmlElement<T>
  querySelectorAll<T extends keyof SVGElementTagNameMap>(selectors: T, element: Element): QuerySelectorAllSvgElement<T>
  querySelectorAll<T extends Element>(selector: string, element: Element): QuerySelectorAllGenericElement<T>
  querySelectorAll(selector: string, root: Element = document.body): Element[] {

    const result : Element[] = [];
    const searchableRoots: Array<Element | ShadowRoot> = [root];

    while(searchableRoots.length > 0) {

      const nextRoot = searchableRoots.pop() as Element;

      const descendants = Array.from(nextRoot.querySelectorAll("*"))

      const matches = descendants.filter(it => it.matches(selector))

      matches.forEach(it => result.push(it))

      const shadowRoots: ShadowRoot[] = descendants
        .reduce((col, it) =>  it.shadowRoot ? col.concat(it.shadowRoot) : col, [] as ShadowRoot[])

      shadowRoots.forEach(it => searchableRoots.push(it))

    }

    return result

  }

  /**
   * This functions exactly like the native {@link Document.querySelector}, but it also traverses shadowDom tree
   * structures as well.
   */
  querySelector<T extends keyof HTMLElementTagNameMap>(selectors: T, element: Element): QuerySelectorHtmlElement<T>
  querySelector<T extends keyof SVGElementTagNameMap>(selectors: T, element: Element): QuerySelectorSvgElement<T>
  querySelector<T extends Element>(selector: string, element: Element): QuerySelectorGenericElement<T>
  querySelector(selector: string, root: Element = document.body): Element | null {

    const searchableRoots: Array<Element | ShadowRoot> = [root];
    let result = null;

    while(searchableRoots.length > 0) {

      const nextRoot = searchableRoots.pop() as Element;

      const match = nextRoot.querySelector(selector);

      if(match) {
        result = match;
        break
      }

      const descendants = Array.from(nextRoot.querySelectorAll("*"))

      const shadowRoots = descendants.filter(it => !!it.shadowRoot)

      shadowRoots.forEach(it => searchableRoots.push(it.shadowRoot as ShadowRoot))

    }

    return result
  }

}