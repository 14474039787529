import { TabConstants } from "@/common/constant/TabConstants";
import {
  ApplicantBusinessFactory,
  ApplicantFactory,
  ApplicantPersonFactory,
  TabFactory
} from "@/common/services/services.module";
import { inject, injectable } from "inversify";
import type IWorkspaceStore from "@/common/services/Workspace/IWorkspaceStore";
import type IWorkspaceTabEntityFactory from "./IWorkspaceTabEntityFactory";
import { ApplicantConstant } from "@/common/constant/ApplicantConstant";
import { NotificationConstants } from "@/common/constant/NotificationConstants";
import { $injByInterface } from "@/common/decorators/depinject";
import type IWorkspaceEntityInfoFactory from "./IWorkspaceEntityInfoFactory";
import WORKFLOW_TYPES from "@/common/enums/workflowTypesEnum";
import DEPENDENCYTYPES from "@/common/dependency.types";
import type IRouterService from "@/common/services/utils/IRouterService";

@injectable()
class WorkspaceTabApplicantFactory implements IWorkspaceTabEntityFactory {
  constructor(
    @inject(ApplicantFactory) private applicantFactory: ApplicantFactory,
    @inject(TabFactory) private tabFactory: TabFactory,
    @inject(ApplicantPersonFactory) private applicantPersonFactory: ApplicantPersonFactory,
    @inject(ApplicantBusinessFactory) private applicantBusinessFactory: ApplicantBusinessFactory,
    @inject(DEPENDENCYTYPES.IRouterService) private routerService: IRouterService
  ) {}
  getWorkspaceType(): string {
    return this.routerService.currentRoute.value.params.applicantType;
  }
  getWorkflowTitle(workspaceType: string): string {
    if (workspaceType === WORKFLOW_TYPES.PERSON) {
      return ApplicantConstant.PERSON_INFO_WORKFLOW;
    } else {
      return ApplicantConstant.BUSINESS_INFO_WORKFLOW;
    }
  }

  getEntity(workspaceUUID: string, entityId: number): Promise<any> {
    return this.applicantFactory.get(workspaceUUID, entityId);
  }
  getEntityAccountList(workspaceUUID: string, entityId: number): Promise<any> {
    return Promise.resolve([]);
  }
  getEntityValidation(workspaceUUID: string, entityId: number): Promise<any> {
    return this.applicantFactory.getApplicantValidation(workspaceUUID, entityId);
  }
  getEntityInfo(workspaceType: string, workspaceUUID: string, entityId: number): Promise<any> {
    const workspaceEntityInfoFactory = $injByInterface<IWorkspaceEntityInfoFactory>(workspaceType);
    return workspaceEntityInfoFactory.getEntityInfo(workspaceUUID, entityId);
  }
  getEntityTabs(workspaceType: string): Promise<any> {
    return this.tabFactory.getTabsFromWorkflow(TabConstants.APPLICANT, workspaceType);
  }
  getEntityState(entity: any): string {
    return entity.applicantState;
  }

  async getWindowTitle(workspaceType: string, info: any) {
    const workspaceEntityInfoFactory = $injByInterface<IWorkspaceEntityInfoFactory>(workspaceType);
    return workspaceEntityInfoFactory.getWindowTitle(info);
  }

  getLockNotifications(): { message: string; type: string }[] {
    return [{ message: ApplicantConstant.APPLICANT_LOCKED, type: NotificationConstants.INFO }];
  }
  getExistingEntityMessage(): { message: string; type: string }[] {
    return [{ message: ApplicantConstant.APPLICANT_EXISTING, type: NotificationConstants.INFO }];
  }

  setEntityToStore(workspaceUUID: string, applicantId: number): Promise<any> {
    const workspaceStore = $injByInterface<IWorkspaceStore>("IWorkspaceStore");
    return this.applicantFactory.get(workspaceUUID, applicantId).then((applicant) => {
      workspaceStore.activeApplicantId = applicant.applicantId;
      return (workspaceStore.applicant = applicant);
    });
  }

  updateWorkspaceEntityState(workspaceUUID: string, applicantId: number, state: string): Promise<any> {
    const workspaceStore = $injByInterface<IWorkspaceStore>("IWorkspaceStore");
    if (Number(applicantId) === workspaceStore.applicant.applicantId) {
      return this.applicantFactory.updateApplicantState(workspaceUUID, applicantId, state);
    }
    return Promise.resolve();
  }
}
export default WorkspaceTabApplicantFactory;
