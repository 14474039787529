<template>
  <div class="disclosure-heading">
    <jha-flex-wrapper slot="header" class="header">
      <jha-icon-fi class="head-icon-padding"></jha-icon-fi>
      <div class="disclosure-head-content">{{ account.title }}</div>
    </jha-flex-wrapper>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "bltAccounts",
  props: {
    account: {
      type: Object,
      required: true
    }
  }
});
</script>

<style scoped>
.disclosure-heading {
  background: #f5f5f5;
  padding: var(--jh-space-200);
  border-radius: 4px;
  color: #575a5d;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
.head-icon-padding {
  padding-right: var(--jh-space-200);
  width: 20px;
  height: 20px;
  --jha-icon-fill-color: #5f6468;
}
.disclosure-head-content {
  font-size: 12px;
  font-weight: 500;
}
@media (max-width: 599px) {
  .disclosure-heading {
    margin-right: var(--jh-space-400);
  }
}
</style>
