const UI_FIELDS_CATEGORIES = Object.freeze({
  OPEN: {
    ADDRESS: 21,
    DEMOGRAPHICS: 52,
    PERSON_INFO: 34,
    BUSINESS_INFO: 22,
    EMPLOYMENT: 28,
  },
  BRANCH: {
    ADDRESS: 3,
    DEMOGRAPHICS: 53,
    PERSON_INFO: 9,
    BUSINESS_INFO: 49,
    EMPLOYMENT: 51,
  },
});

export default UI_FIELDS_CATEGORIES;