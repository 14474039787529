import { UiImageFactory } from "@/common/services/services.module";
import { inject, injectable } from "inversify";

@injectable()
class BltToolbarFactory {
  constructor(@inject(UiImageFactory) private uiImageFactory: UiImageFactory) {}

  getImageList = (navItems: any[]) => {
    return Promise.all(
      navItems.map((navItem) => {
        return this.setImage(navItem);
      })
    );
  };

  async setImage(navItem: { icon: any; imageB64: any }) {
    const image = await this.uiImageFactory.get(navItem.icon);
    navItem.imageB64 = image;
    return navItem;
  }
}
export default BltToolbarFactory;
